import React, { useState, useEffect } from "react";
import { Form, Button, Col, Table, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  PencilFill,
  Trash3,
  EyeFill,
  BoxArrowInUp,
} from "react-bootstrap-icons";
import Navbar from "../common/navbar";
import Sidebar from "../sidebar/sidebar";
import DeleteItinerary from "./Itinarary/DeleteItinerary";
import ViewItinerary from "./Itinarary/ViewItinerary";
import EditItinerary from "./Itinarary/EditItinerary";
import CustomTimeInput from "./Itinarary/CustomTimeInput";
// import CustomTimeInput from "./CustomTimeInput";
// import EditItinerary from "./EditItinerary";
// import ViewItinerary from "./ViewItinerary";
// import DeleteItinerary from "./DeleteItinerary";
// import Pagination from "../Pagination/pagination";

const Itinerary = () => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const { userData, token } = useSelector((state) => state);
  const [allToursByVendorId, setAllToursByVendorId] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [itineraries, setItineraries] = useState([
    { dayNumber: 1, description: "", startTime: "", endTime: "" },
  ]);
  const [createdItineraries, setCreatedItineraries] = useState([]);
  const [allItineraries, setAllItineraries] = useState([]);
  const [selectedTourID, setSelectedTourID] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchAllToursByVendorId();
  }, []);

  useEffect(() => {
    if (selectedTourID) {
      fetchAllItineraries();
    }
  }, [selectedTourID]);

  const fetchAllToursByVendorId = async () => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/enterprise/${userData.data.enterpriseCompanyId}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      setAllToursByVendorId(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const fetchAllItineraries = async () => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/itineraries/schedule/${selectedTourID}`
      );
      if (!response.ok) {
        throw new Error(
          `Error fetching all itineraries: ${response.statusText}`
        );
      }
      const data = await response.json();
      setAllItineraries(data.data);
      console.log("Fetched Itineraries:", data.data);
    } catch (error) {
      console.error("Error fetching all itineraries:", error);
      toast.error("Failed to fetch itineraries.");
    }
  };

  const handleScheduleChange = (e) => {
    setSelectedTourID(e.target.value);
    const selectedTour = allToursByVendorId.find((tour) =>
      tour.schedules.some((schedule) => schedule.scheduleId === e.target.value)
    );
    const selectedSchedule = selectedTour?.schedules.find(
      (schedule) => schedule.scheduleId === e.target.value
    );
    setSelectedSchedule(selectedSchedule);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEdit = (itinerary) => {
    setSelectedItinerary(itinerary);
    setShowEditPopup(true);
  };

  const handleView = (itinerary) => {
    setSelectedItinerary(itinerary);
    setShowViewPopup(true);
  };

  const handleDelete = (itinerary) => {
    setSelectedItinerary(itinerary);
    setShowDeletePopup(true);
  };

  const handleCreate = async () => {
    const payload = itineraries.map(
      ({ dayNumber, description, startTime, endTime }) => ({
        dayNumber,
        description,
        startTime,
        endTime,
      })
    );

    try {
      const response = await fetch(
        `${BaseAPI}/tour/create/itinerary/schedule/${selectedSchedule.scheduleId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error saving itinerary: ${errorText}`);
      }
      const data = await response.json();
      toast.success("Itinerary added successfully!");
      setCreatedItineraries([...createdItineraries, ...payload]);
      setShowModal(false);
      fetchAllItineraries();
    } catch (error) {
      console.error("Error creating itinerary:", error);
      toast.error("Failed to save itinerary.");
    }
  };

  const handleAddRow = () => {
    setItineraries([
      ...itineraries,
      {
        dayNumber: itineraries.length + 1,
        description: "",
        startTime: "",
        endTime: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const newItineraries = itineraries.filter((_, i) => i !== index);
    setItineraries(newItineraries);
  };

  const handleInputChange = (index, field, value) => {
    const newItineraries = itineraries.map((itinerary, i) =>
      i === index ? { ...itinerary, [field]: value } : itinerary
    );
    setItineraries(newItineraries);
  };

  const getCurrentPageItineraries = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return allItineraries.slice(startIndex, endIndex);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  return (
    <>
      <div>
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <Form.Group
                  as={Col}
                  controlId="formCategory"
                  className="w-25 ms-3 mt-3"
                >
                  <Form.Label>Schedule Tour Name:</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    className="w-25"
                    value={selectedTourID}
                    onChange={handleScheduleChange}
                  >
                    <option value="">Select a Tour</option>
                    {allToursByVendorId &&
                      Array.isArray(allToursByVendorId) &&
                      allToursByVendorId.map((tour) =>
                        tour.schedules && tour.schedules.length > 0 ? (
                          tour.schedules.map((scheduleItem) => (
                            <option
                              key={scheduleItem.scheduleId}
                              value={scheduleItem.scheduleId}
                            >
                              {scheduleItem.scheduleTitle}
                            </option>
                          ))
                        ) : (
                          <option key={tour.id} disabled>
                            No scheduled tours available
                          </option>
                        )
                      )}
                  </Form.Control>
                </Form.Group>
              </div>
              {selectedSchedule && (
                <>
                  <Button
                    variant="primary"
                    className="float-end me-3 mb-4 font-common"
                    onClick={handleShowModal}
                  >
                    Add Itinerary
                  </Button>

                  <Modal show={showModal} size="lg" onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Itinerary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Table
                        className="table-no-vertical-lines"
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Sr No</th>
                            <th>Day</th>
                            <th>Description</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itineraries &&
                            Array.isArray(itineraries) &&
                            itineraries.map((itinerary, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    value={itinerary.dayNumber}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "dayNumber",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    value={itinerary.description}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <CustomTimeInput
                                    value={itinerary.startTime}
                                    onChange={(value) =>
                                      handleInputChange(
                                        index,
                                        "startTime",
                                        value
                                      )
                                    }
                                    
                                  />
                                </td>
                                <td>
                                  <CustomTimeInput
                                    value={itinerary.endTime}
                                    onChange={(value) =>
                                      handleInputChange(index, "endTime", value)
                                    }
                                  />
                                </td>
                                <td>
                                  <Button
                                    variant="danger"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <Trash3 />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>

                      <Button
                        variant="success"
                        className="mt-3"
                        onClick={handleAddRow}
                      >
                        Add More
                      </Button>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleCreate}>
                        Create
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <div className="row" style={{ width: "100%" }}>
                    <div
                      className="col-8 mt-5 ms-3 me-5"
                      style={{ width: "100%" }}
                    >
                      <div className="table-responsive shadow rounded me-3">
                        <h6 className="ms-2">Created Itineraries</h6>
                        <table className="table table-center bg-white mb-0">
                          <thead>
                            <tr>
                              <th className="border-bottom p-3">Sr.No.</th>
                              <th className="border-bottom p-3">Day</th>
                              <th className="border-bottom p-3">Description</th>
                              <th className="border-bottom p-3">Start Time</th>
                              <th className="border-bottom p-3">End Time</th>
                              <th className="border-bottom ">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allItineraries && allItineraries.length > 0 ? (
                              getCurrentPageItineraries().map(
                                (itinerary, index) => (
                                  <tr key={itinerary.itineraryId}>
                                    <td className="text-center p-3">
                                      {(currentPage - 1) * itemsPerPage +
                                        index +
                                        1}
                                    </td>
                                    <td className="p-3">
                                      {itinerary.dayNumber}
                                    </td>
                                    <td className="p-3">
                                      {itinerary.description}
                                    </td>
                                    <td className="p-3 text-center">
                                      {itinerary.startTime}
                                    </td>
                                    <td className="p-3 text-center">
                                      {itinerary.endTime}
                                    </td>
                                    <td className="text-center p-3">
                                      {/* <EyeFill
                                    className="me-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleView(itinerary)}
                                />
                                <PencilFill
                                    className="me-2"
                                    onClick={() => handleEdit(itinerary)}
                                    style={{ cursor: "pointer" }}
                                />
                                <Trash3
                                    className="me-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRemoveRow(index)} 
                                /> */}

                                      <EyeFill
                                        onClick={() => handleView(itinerary)}
                                        style={{
                                          fontSize: "17px",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <PencilFill
                                        onClick={() => handleEdit(itinerary)}
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                      />
                                      <Trash3
                                        onClick={() => handleDelete(index)}
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center p-3">
                                  No itineraries found for this schedule.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <EditItinerary
                    show={showEditPopup}
                    handleClose={() => setShowEditPopup(false)}
                    itineraries={allItineraries}
                    scheduleId={selectedSchedule.scheduleId}
                    token={token}
                  />

                  <ViewItinerary
                    show={showViewPopup}
                    handleClose={() => setShowViewPopup(false)}
                    itineraries={allItineraries}
                    scheduleId={selectedSchedule.scheduleId}
                    token={token}
                  />

                  <DeleteItinerary
                    show={showDeletePopup}
                    handleClose={() => setShowDeletePopup(false)}
                    itineraries={allItineraries}
                    scheduleId={selectedSchedule.scheduleId}
                    token={token}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Itinerary;
