import { createStore } from 'redux';

// Reducer function to handle login state
const loginReducer = (state = { userData: null, token: null }, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { userData: action.userData, token: action.token };
    default:
      return state;
  }
};

// Load initial state from sessionStorage if available
const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

// Save state to sessionStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('reduxState', serializedState);
  } catch {
    // Ignore write errors
  }
};

// Create Redux store with initial state
const initialState = loadState();
const store = createStore(loginReducer, initialState);

// Subscribe to store changes to save state to sessionStorage
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
