import React from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

const ViewItinerary = ({ show, handleClose, itineraries, scheduleId }) => {
  const filteredItineraries = itineraries?.filter(
    (itinerary) => itinerary.scheduleId === scheduleId
  )?.sort((a, b) => a.dayNumber - b.dayNumber) || [];

  return (
    <Modal
      className="modal-xl hide-scrollbar font-common"
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog-centered"
      style={{ height: "80%", width: "60%", marginLeft: "320px", position: "fixed" }}
    >
      <Modal.Header closeButton className="modal-header-fixed">
        <Modal.Title className="font-common">View Itinerary</Modal.Title>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        <Form className="font-common">
          {filteredItineraries.length > 0 ? (
            filteredItineraries.map((item, index) => (
              <Row className="mb-3" key={index}>
                <Form.Group as={Col} controlId={`formDayNumber${index}`}>
                  <Form.Label>Day</Form.Label>
                  <Form.Control type="text" name="dayNumber" value={item.dayNumber} readOnly />
                </Form.Group>
                <Form.Group as={Col} controlId={`formDescription${index}`}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={1} name="description" value={item.description} readOnly />
                </Form.Group>
                <Form.Group as={Col} controlId={`formStartTime${index}`}>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control type="text" name="startTime" value={item.startTime} readOnly />
                </Form.Group>
                <Form.Group as={Col} controlId={`formEndTime${index}`}>
                  <Form.Label>End Time</Form.Label>
                  <Form.Control type="text" name="endTime" value={item.endTime} readOnly />
                </Form.Group>
              </Row>
            ))
          ) : (
            <p>No itineraries available for this schedule.</p>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer-fixed">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewItinerary;
