import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomTimeInput from "./CustomTimeInput";
import axios from "axios";
import { Trash3 } from "react-bootstrap-icons";

const EditItinerary = ({ show, handleClose, itineraries = [], scheduleId, token }) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const [editedItineraries, setEditedItineraries] = useState([]);
  

  useEffect(() => {
    if (Array.isArray(itineraries) && itineraries.length > 0) {
      setEditedItineraries([...itineraries].sort((a, b) => a.dayNumber - b.dayNumber));
    } else {
      setEditedItineraries([]);
    }
  }, [itineraries]);

  const handleFieldChange = (index, field, value) => {
    const newItineraries = [...editedItineraries];
    newItineraries[index][field] = value;
    setEditedItineraries(newItineraries);
  };

  const handleAddRow = () => {
    setEditedItineraries([
      ...editedItineraries,
      { dayNumber: "", description: "", startTime: "", endTime: "" },
    ]);
  };

  const handleRemoveRow = async (index) => {
    const itineraryId = editedItineraries[index]?.itineraryId;
  
    try {
      const response = await axios.post(
        `${BaseAPI}/tour/delete/itineraries/${scheduleId}`,
        [itineraryId],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200 ) {
        toast.success("Itinerary deleted successfully.");
        setEditedItineraries((prevItineraries) =>
          prevItineraries.filter((_, i) => i !== index)
        );
      } else {
        toast.error("Failed to delete itinerary.");
        console.error("Response error:", response.data);
      }
    } catch (error) {
      console.error("Error deleting itinerary:", error);
      toast.error("An error occurred while deleting the itinerary.");
    }
  };
  

  const handleUpdate = async () => {
    if (!scheduleId) {
      toast.error("Schedule ID is missing.");
      return;
    }

    const payload = editedItineraries.map(({ dayNumber, description, startTime, endTime }) => ({
      dayNumber,
      description,
      startTime,
      endTime,
    }));

    try {
      const response = await fetch(`${BaseAPI}/tour/update/itinerary/schedule/${scheduleId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating itinerary: ${errorText}`);
      }

      toast.success("Itinerary updated successfully!");
      handleClose();
    } catch (error) {
      console.error("Error updating itinerary:", error);
      toast.error("Failed to update itinerary.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="font-common">Edit Itinerary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover className="font-common">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Day</th>
              <th>Description</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {editedItineraries.length > 0 ? (
              editedItineraries.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.dayNumber}
                      onChange={(e) => handleFieldChange(index, "dayNumber", e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      value={item.description}
                      onChange={(e) => handleFieldChange(index, "description", e.target.value)}
                    />
                  </td>
                  <td>
                    <CustomTimeInput
                      value={item.startTime || ""}
                      onChange={(value) => handleFieldChange(index, "startTime", value)}
                    />
                  </td>
                  <td>
                    <CustomTimeInput
                      value={item.endTime || ""}
                      onChange={(value) => handleFieldChange(index, "endTime", value)}
                    />
                  </td>
                  <td>
                  <Button variant="danger" onClick={() => handleRemoveRow(index)}>
                  <Trash3 />
                  </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No itineraries available for this schedule.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Button variant="success" className="mt-3" onClick={handleAddRow}>
          Add More
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {editedItineraries.length > 0 && (
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditItinerary;
