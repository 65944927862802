import {
  Form,
  Button,
  Table,
  Container,
  Col,
  Row,
  Modal,
  Card,
  Carousel,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  PencilFill,
  Trash3,
  PlusCircle,
  PersonCircle,
  EyeFill,
  ArrowRight,
  ArrowLeft,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminToursEdit from "./AdminToursEdit";
import CustomPagination from "./CustomPagination";
import TourDelete from "./TourDelete";
import TourView from "./TourView";
import Navbar from "../../common/navbar";
import AdminSidebar from "../adminSidebar";
import blankImg from "../../../assets/images/download (2).png";

import he from "he";

function AdminTours() {
  const baseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;
  const baseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const [allCompany, setAllCompany] = useState([]);

  const [toursByCompany, setToursByCompany] = useState([]);
  const [selectedEnterpriseCompanyId, setselectedEnterpriseCompanyId] =
    useState("");
  const [showTable, setTable] = useState(false);
  const [showViewPopup, setshowViewPopup] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [showEditPopup, setshowEditPopup] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(30);
  const [themes, setThemes] = useState([]);

  const [visibleToursCount, setVisibleToursCount] = useState(8);
  const [displayedTours, setDisplayedTours] = useState([]);
  const [hasMoreTours, setHasMoreTours] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  useEffect(() => {
    getAllCompany();
    fetchAllThemes();
  }, []);

  const getAllCompany = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/rest/auth/all/enterprise`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setAllCompany(data.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
      toast.error("Failed to fetch companies");
    }
  };

  const fetchAllToursByCompanyId = async (companyId) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${baseAPI}/tour/enterprise/${companyId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching tours: ${response.statusText}`);
      }
      const data = await response.json();
      setToursByCompany(data.data);
      setDisplayedTours(data.data.slice(0, 8)); // Initially show 8 tours
      setVisibleToursCount(8);
      setHasMoreTours(data.data.length > 8); // Check if there are more than 8 tours
      setTable(true);
    } catch (error) {
      console.error("Error fetching tours:", error);
      toast.error("Failed to fetch tours");
    }
  };

  const handleShowMoreTours = () => {
    const nextToursCount = visibleToursCount + 8;
    setDisplayedTours(toursByCompany.slice(0, nextToursCount));
    setVisibleToursCount(nextToursCount);
    setHasMoreTours(toursByCompany.length > nextToursCount);
  };

  const handleShowLessTours = () => {
    setDisplayedTours(toursByCompany.slice(0, 8));
    setVisibleToursCount(8);
    setHasMoreTours(toursByCompany.length > 8);
  };

  const handleInputChange = (e) => {
    setselectedEnterpriseCompanyId(e.target.value);
    setTable(true);
  };

  useEffect(() => {
    if (selectedEnterpriseCompanyId) {
      fetchAllToursByCompanyId(selectedEnterpriseCompanyId);
    }
  }, [selectedEnterpriseCompanyId]);

  const handleViewClick = (tour) => {
    console.log("tour", tour);
    setshowViewPopup(true);
    setSelectedTour(tour);
  };

  const handleEditClick = (tours) => {
    setshowEditPopup(true);
    setSelectedTour(tours);
  };

  const handleDeleteClick = (tours) => {
    setshowDeletePopup(true);
    setSelectedTour(tours);
  };

  const getCurrentData = () => {
    if (!toursByCompany) {
      return [];
    }
    return toursByCompany.slice(0, visibleToursCount);
  };

  // const getCurrentData = () => {
  //   if (!toursByCompany) {
  //     return [];
  //   }
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   return toursByCompany.slice(indexOfFirstItem, indexOfLastItem);
  // };

  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [fullDescription, setFullDescription] = useState("");

  const handleDescriptionClick = (description) => {
    setFullDescription(description);
    setShowDescriptionModal(true);
  };

  const handleDescriptionModalClose = () => {
    setShowDescriptionModal(false);
    setFullDescription("");
  };

  const truncateDescription = (description) => {
    const decodedDescription = he.decode(description);
    const maxLength = 30;
    if (decodedDescription.length > maxLength) {
      return `${decodedDescription.substring(0, maxLength)}...`;
    }
    return decodedDescription;
  };

  const fetchAllThemes = async () => {
    try {
      const response = await fetch(`${baseAPI}/tour/all/themes`);
      if (!response.ok) {
        throw new Error("Failed to fetch themes");
      }
      const data = await response.json();
      setThemes(data.data); // Assuming the themes are in data.data
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const getThemeName = (themeId) => {
    const theme = themes.find((t) => t.tourThemeId === themeId);
    return theme ? theme.themeName : "Theme Not Available";
  };


  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    const url = `https://eurekatrip.com/#/tour/${tourId}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div>
        <div>
          <AdminSidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <div className="col-8 mt-2 ms-3 me-4" style={{ width: "100%" }}>
                  <Form.Group
                    className="w-50 ms-3 mt-3"
                    as={Col}
                    controlId="formCategory"
                  >
                    <Form.Label className=" ms-2 mt-3">
                      Company Name:
                    </Form.Label>
                    <Form.Control
                      className="w-50 border-1 border-dark"
                      as="select"
                      value={selectedEnterpriseCompanyId}
                      onChange={handleInputChange}
                      name="companyName"
                    >
                      <option value="">Select company name</option>
                      {Array.isArray(allCompany) &&
                        allCompany.map((company) => (
                          <option
                            key={company.companyName}
                            value={company.enterpriseCompanyId}
                          >
                            {company.companyName}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  {toursByCompany && showTable && (
                    <div className="row">
                      <div className="col-8 mt-5" style={{ width: "100%" }}>
                        <div className=" me-5">
                          {/* <Table className="table table-center bg-white mb-0 me-4">
                          <thead>
                            <tr>
                              <th className="border-bottom p-3">Sr.No.</th>
                              <th
                                className="border-bottom p-3"
                                style={{ minWidth: "220px" }}
                              >
                                Tour Name
                              </th>
                              <th className="border-bottom p-3 text-center">
                                Max Participants
                              </th>
                              <th className="border-bottom p-3 text-center">
                                Description
                              </th>
                              <th className="border-bottom p-3 text-center ">
                                Theme Name
                              </th>
                              <th className="border-bottom p-3 text-center">
                                Price
                              </th>
                              <th className="border-bottom p-3 text-center">
                                Duration Days
                              </th>
                              <th className="border-bottom  text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {toursByCompany &&
                              getCurrentData().map((tours, index) => (
                                <tr key={index}>
                                  <td>
                                    {(currentPage - 1) * itemsPerPage +
                                      index +
                                      1}
                                  </td>
                                  <td>{tours.title}</td>
                                  <td className="text-center">
                                    {tours.maxParticipants}
                                  </td>

                                  <td className="tour-td text-center">
                                    <div className="truncated-description">
                                      {truncateDescription(tours.description)}
                                      {tours.description.length > 100 && (
                                        <span
                                          className="see-more-link"
                                          onClick={() =>
                                            handleDescriptionClick(
                                              he.decode(tours.description)
                                            )
                                          }
                                        >
                                          See more
                                        </span>
                                      )}
                                    </div>
                                  </td>

                                 
                                  <td
                                    className="text-center"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {tours.tourTheme &&
                                    tours.tourTheme.length > 0
                                      ? tours.tourTheme.map(
                                          (theme, themeIndex) => (
                                            <React.Fragment key={themeIndex}>
                                              {theme.category?.categoryName ||
                                                "No Category"}
                                              {theme.subCategory
                                                ? ` - ${theme.subCategory.subCategoryName}`
                                                : ""}
                                              {themeIndex <
                                                tours.tourTheme.length - 1 &&
                                                ", "}
                                              <br />
                                            </React.Fragment>
                                          )
                                        )
                                      : "No Themes Available"}
                                  </td>

                                  <td className="text-center">
                                    {tours?.price}
                                  </td>
                                  <td className="text-center">
                                    {tours?.duration} {tours?.durationTime}
                                  </td>
                                  <td className="text-center">
                                    <EyeFill
                                      onClick={() => handleViewClick(tours)}
                                    />
                                    <span className="ms-3">
                                      <PencilFill
                                        onClick={() => handleEditClick(tours)}
                                      />
                                    </span>
                                    <span className="ms-3">
                                      <Trash3
                                        onClick={() => handleDeleteClick(tours)}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table> */}

                          <Row>
                            {toursByCompany &&
                              getCurrentData().map((tour, index) => (
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={3}
                                  key={index}
                                  className="mb-4"
                                >
                                  <Card
                                    className="h-100"
                                    style={{ border: "none" }}
                                  >
                                    <div className="carousel-hover">
                                      <Carousel
                                        className="carousel-overlay"
                                        interval={null}
                                        nextIcon={<ArrowRight />}
                                        prevIcon={<ArrowLeft />}
                                      >
                                        {tour.tourAssets &&
                                        tour.tourAssets.length > 0 ? (
                                          tour.tourAssets.map(
                                            (asset, assetIdx) => (
                                              <Carousel.Item
                                                key={assetIdx}
                                                className="card-img-container"
                                              >
                                                <img
                                                  onClick={() => handleTourCardClick(tour)}
                                                  src={
                                                    asset.assetUrl || blankImg
                                                  }
                                                  alt={`Slide ${assetIdx}`}
                                                  onError={(e) => {
                                                    console.log(
                                                      "Image failed to load. Setting to default image."
                                                    );
                                                    e.target.src = blankImg;
                                                  }}
                                                  className="img-fluid"
                                                  style={{ cursor: "pointer" }}
                                                />
                                              </Carousel.Item>
                                            )
                                          )
                                        ) : (
                                          <Carousel.Item className="card-img-container">
                                            <img
                                              onClick={() => handleTourCardClick(tour)}
                                              src={blankImg}
                                              alt="Default image"
                                              className="img-fluid"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Carousel.Item>
                                        )}
                                      </Carousel>
                                      <div className="overlay-text">
                                        {getThemeName(
                                          tour.tourTheme[0]?.themeId
                                        )}
                                      </div>
                                    </div>

                                    <div className="d-flex flex-column mt-2">
                                      <span className="font-medium-title feature-title">
                                        {tour.title}
                                      </span>

                                      <div className="d-flex justify-content-between mt-auto">
                                        <span className="text-muted">
                                          Max Participants:{" "}
                                          {tour.maxParticipants}
                                        </span>
                                        <span className="text-muted">
                                          Duration: {tour.duration}{" "}
                                          {tour.durationTime}
                                        </span>
                                      </div>

                                      {/* <p className="text-muted mt-2">
                                        {truncateDescription(tour.description)}
                                      </p> */}

                                      <div className="d-flex justify-content-between mt-3">
                                        <div
                                          role="button"
                                          className="location-text text-wrap"
                                          // onClick={() => handleShowModal(tour)}
                                        >
                                          {/* <span className="font-medium-title feature-title">
                                            Schedule{" "}
                                            <i className="mdi mdi-calendar-check"></i>
                                          </span> */}
                                        </div>

                                        <div>
                                          {/* <span
                                            className="font-medium-title feature-title"
                                            onClick={() => uploadImages(tour)}
                                          >
                                            Upload image
                                            <img src={uploadImageIcon} alt="" style={{ width: '20px' }} />
                                          </span> */}
                                        </div>

                                        <div>
                                          {/* <EyeFill
                                            onClick={() =>
                                              handleViewClick(tour)
                                            }
                                          />
                                          <PencilFill
                                            onClick={() =>
                                              handleEditClick(tour)
                                            }
                                            className="ms-3"
                                          /> */}
                                          {/* <Trash3
                                            onClick={() =>
                                              handleDeleteClick(tour)
                                            }
                                            className="ms-3"
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              ))}
                          </Row>

                          <div className="text-end mt-1">
                            {toursByCompany.length > visibleToursCount && (
                              <Button onClick={handleShowMoreTours}>
                                Show More
                              </Button>
                            )}

                            {visibleToursCount > 8 && (
                              <Button
                                onClick={handleShowLessTours}
                                className="ms-2"
                              >
                                Show Less
                              </Button>
                            )}
                          </div>

                          {/* Description Modal */}
                          <Modal
                            show={showDescriptionModal}
                            onHide={handleDescriptionModalClose}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Tour Description</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{fullDescription}</Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleDescriptionModalClose}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <CustomPagination
                    className="justify-content-end"
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalItems={toursByCompany?.length}
                    itemsPerPage={itemsPerPage}
                  /> */}

                  {showEditPopup && (
                    <AdminToursEdit
                      showEditPopup={showEditPopup}
                      setshowEditPopup={setshowEditPopup}
                      handleClose={() => setshowEditPopup(false)}
                      tour={selectedTour}
                    />
                  )}

                  {showViewPopup && (
                    <TourView
                      showViewPopup={showViewPopup}
                      setshowViewPopup={setshowViewPopup}
                      handleClose={() => setshowViewPopup(false)}
                      selectedTour={selectedTour}
                    />
                  )}

                  {showDeletePopup && (
                    <TourDelete
                      showDeletePopup={showDeletePopup}
                      setshowDeletePopup={setshowDeletePopup}
                      handleClose={() => setshowDeletePopup(false)}
                      tour={selectedTour}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminTours;
