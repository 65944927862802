import React, { useState, useEffect } from "react";
import backgroundImage from "../../assets/images/woman-2594934_1920.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { Modal, Button, Form } from "react-bootstrap";
import AuthService from "../services/auth.service";

function SignIn() {
  const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [showResetModal, setShowResetModal] = useState(false);
  const [showOtpVerification, setShowOtpVerification] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const handleCloseResetModal = () => setShowResetModal(false);
  const handleShowResetModal = () => setShowResetModal(true);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      const token = AuthService.getAuthToken();
      const decodedTokenData = jwtDecode(token);

      if (decodedTokenData.role === "ADMIN") {
        navigate("/admin-dashboard");
      } else if (decodedTokenData.role === "ENTERPRISE_COMPANY_ADMIN") {
        navigate("/vendor-dashboard");
      } else if (decodedTokenData.role === "ENTERPRISE_COMPANY_EXECUTIVE") {
        navigate("/vendor-dashboard");
      }
    }
  }, [navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const loginResponse = await AuthService.Login(
        credentials.username,
        credentials.password
      );

      if (!loginResponse.ok) {
        throw new Error("Incorrect username or password");
      }

      const data = await loginResponse.json();

      localStorage.setItem("userData", JSON.stringify(data));
      localStorage.setItem("token", data.token);

      const decodedTokenData = jwtDecode(data.token);
      dispatch({
        type: "LOGIN",
        userData: data,
        token: data.token,
        userId: data.userId,
      });

      toast.success("Login successful");
      if (decodedTokenData.role === "ADMIN") {
        navigate("/admin-dashboard");
      } else if (decodedTokenData.role === "ENTERPRISE_COMPANY_ADMIN") {
        navigate("/vendor-dashboard");
      } else if (decodedTokenData.role === "ENTERPRISE_COMPANY_EXECUTIVE") {
        navigate("/vendor-dashboard");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();
    console.log("Password reset for:", email);

    try {
      const response = await fetch(`${BaseUrl}/rest/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success("OTP has been sent to your email.");

        handleCloseResetModal();

        setShowOtpVerification(true);
      } else {
        alert(data.message || "Failed to send password reset link.");
      }
    } catch (error) {
      console.error("Error sending reset password request:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    otp.forEach((data, index) => {
      if (data !== "") {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput && document.activeElement !== nextInput) {
          nextInput.focus();
        }
      }
    });
  }, [otp]);

  const otpVerification = async () => {
    const otpData = {
      email: email,
      inputOTP: otp.join(""),
    };

    try {
      const response = await fetch(`${BaseUrl}/rest/auth/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otpData),
      });

      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        toast.success("OTP verified successfully");
        setShowOtpVerification(false);
        setShowPasswordModal(true);
      } else {
        setErrorMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error.message);
      setErrorMessage("Failed to verify OTP. Please try again later.");
    }
  };

  const validatePassword = (password, confirmPassword) => {
    let errors = {};
    if (!password) {
      errors.passwordRequired = "Password is required";
    } else {
      if (!/[a-z]/.test(password))
        errors.lowercase = "At least one lowercase letter required";
      if (!/[A-Z]/.test(password))
        errors.uppercase = "At least one uppercase letter required";
      if (!/[0-9]/.test(password))
        errors.number = "At least one number required";
      if (!/[\!\@\#\$\%\^\&\*]/.test(password))
        errors.specialChar =
          "At least one special character required (!@#$%^&*)";
      if (password.length < 8)
        errors.length = "Password must be at least 8 characters long";
    }
    if (password && confirmPassword && password !== confirmPassword) {
      errors.confirmPasswordMatch = "Passwords do not match";
    }
    return errors;
  };


  const setPasswordHandler = async (event) => {
    event.preventDefault();
    const errors = validatePassword(password, confirmPassword);
    if (Object.keys(errors).length === 0) {
      try {
        const response = await fetch(`${BaseUrl}/rest/auth/set-password`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password, confirmPassword })
        });

        const result = await response.json();
        if (response.ok && result.statusCode === 200) {
          toast.success("Password set successfully. Please login.");
          setShowPasswordModal(false);
          navigate("/");
        } else {
          throw new Error(result.message || "Error setting password");
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      setFormErrors(errors);
    }
  };

  // const setPasswordHandler = async (event) => {
  //   event.preventDefault();

  //   const errors = validatePassword(password, confirmPassword);
  //   if (Object.keys(errors).length === 0) {
  //     const data = {
  //       email: email,
  //       password: password,
  //       confirmPassword: confirmPassword,
  //     };

  //     try {
  //       const response = await fetch(`${BaseUrl}/rest/auth/set-password`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       });

  //       const result = await response.json();

  //       if (response.ok && result.statusCode === 200) {
  //         toast.success("Password set successfully. Please login.");
  //         setShowPasswordModal(false);
  //         navigate("/");
  //       } else {
  //         throw new Error(result.message || "Error setting password");
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   } else {
  //     setFormErrors(errors);
  //   }
  // };

  const OtpVerificationForm = () => (
    <div className="otp-verification-form">
      <div className="otp-header">
        <h4>Enter OTP Code</h4>
        <div>
          <span>
            OTP sent to <strong>{email}</strong>. Please check your inbox.
          </span>
        </div>
      </div>
      <div className="otp-inputs">
        {otp.map((data, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            className="otp-input rounded"
            type="text"
            name="otp"
            maxLength="1"
            value={data}
            onChange={(e) => handleOtpChange(e.target, index)}
            onFocus={(e) => e.target.select()}
          />
        ))}
      </div>
      <div>
        <Button className="btn btn-primary" onClick={otpVerification}>
          Verify OTP
        </Button>
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
      </div>
      <div className="resend-otp mt-1">
        <a href="" onClick={handleResetSubmit}>
          Resend OTP
        </a>
      </div>
    </div>
  );

  return (
    <>
      <section className="cover-user">
        <div className="container-fluid px-0">
          <div className="row g-0 position-relative">
            <div className="col-lg-4 cover-my-30 order-2">
              <div className="cover-user-img d-flex align-items-center">
                <div className="row">
                  <div className="col-12">
                    <div
                      className="card login-page border-0"
                      style={{ zIndex: 1 }}
                    >
                      <div className="card-body p-0">
                        <h4 className="font-medium-titles text-center">
                          Login
                        </h4>
                        <form
                          className="login-form mt-4"
                          onSubmit={handleLogin}
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Email <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    name="username"
                                    value={credentials.username}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <i
                                    className={`bi ${
                                      showPassword ? "bi-eye" : "bi-eye-slash"
                                    }`}
                                    onClick={togglePasswordVisibility}
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                    }}
                                  ></i>

                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="d-flex justify-content-between">
                                <div className="mb-3">
                                  <p
                                    className="forgot-pass mb-0"
                                    onClick={handleShowResetModal}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Forgot password?
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mb-0">
                              <div className="d-grid">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={loading}
                                >
                                  {loading ? "Signing in..." : "Sign in"}
                                </button>
                              </div>
                            </div>

                            <div className="col-12 text-center">
                              <p className="mb-0 mt-3">
                                <small className="text-dark me-2">
                                  Don't have an account?
                                </small>
                                <span
                                  className="text-dark"
                                  onClick={handleSignupClick}
                                  style={{ cursor: "pointer" }}
                                >
                                  Sign Up
                                </span>
                              </p>
                            </div>
                          </div>
                        </form>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-8 offset-lg-4 padding-less img order-1"
              data-jarallax='{"speed": 0.5}'
            >
              <img
                src={backgroundImage}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Password Reset Modal */}
      <Modal
        show={showResetModal}
        onHide={handleCloseResetModal}
        centered
        dialogClassName="reset-password-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">
            Reset Your Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Please enter your email address. You will receive a link to create a
            new password via email.
          </p>
          <Form onSubmit={handleResetSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mb-3"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100 mb-2"
              style={{ backgroundColor: "#377DFF", borderColor: "#377DFF" }}
            >
              Send
            </Button>
            <div className="text-center">
              <small>
                Remember your password?{" "}
                <span
                  onClick={handleCloseResetModal}
                  style={{ cursor: "pointer", color: "#377DFF" }}
                >
                  Sign in
                </span>
              </small>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* OTP Verification Modal */}
      <Modal
        show={showOtpVerification}
        onHide={() => setShowOtpVerification(false)}
        centered
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <OtpVerificationForm />
        </Modal.Body>
      </Modal>

      {/* Set Password Modal */}
      <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Set New Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={setPasswordHandler}>
            <Form.Group controlId="formPassword">
              <Form.Label>New Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={!!formErrors.passwordRequired || !!formErrors.lowercase || !!formErrors.uppercase || !!formErrors.number || !!formErrors.specialChar || !!formErrors.length}
                />
                <i
                  className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                ></i>
                <Form.Control.Feedback type="invalid">
                  {formErrors.passwordRequired || formErrors.lowercase || formErrors.uppercase || formErrors.number || formErrors.specialChar || formErrors.length}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group controlId="formConfirmPassword" className="mt-3">
              <Form.Label>Confirm Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isInvalid={!!formErrors.confirmPasswordMatch}
                />
                <i
                  className={`bi ${showConfirmPassword ? "bi-eye" : "bi-eye-slash"}`}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                ></i>
                <Form.Control.Feedback type="invalid">
                  {formErrors.confirmPasswordMatch}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-4">
              Set Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <style jsx>{`
        .reset-password-modal .modal-content {
          border-radius: 15px;
        }
        .reset-password-modal .modal-header {
          border-bottom: none;
        }
        .reset-password-modal .modal-title {
          font-weight: bold;
        }
      `}</style>
    </>
  );
}

export default SignIn;
