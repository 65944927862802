import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
  InputGroup,
} from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import he from "he";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useSelector } from "react-redux";
import { PlusCircle, DashCircle } from "react-bootstrap-icons";
import Select from "react-select";
import CurrencyFlag from "react-currency-flags";
import cc from "currency-codes";
import CreatableSelect from "react-select/creatable";

const EditTour = ({
  tour,
  handleUpdateTour,
  handleCancelEdit,
  onCloseAndRefresh,
}) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const { token } = useSelector((state) => state);
  const [address, setAddress] = useState("");
  const [cancellationPolicies, setCancellationPolicies] = useState([]);
  const [themes, setThemes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [personalizedSubCategories, setPersonalizedSubCategories] = useState(
    []
  );
  const [tagIdentifiers, setTagIdentifiers] = useState([]);
  const [selectedTagIdentifiers, setSelectedTagIdentifiers] = useState([]);
  const [questionDropDown, setQuestionsDropdown] = useState(false);
  const [tagIdentifiersDropDown, setTagIdentifiersDropdown] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [selections, setSelections] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const [tourData, setTourData] = useState({
    title: "",
    shortDescription: "",
    description: "",
    highlights: "",
    included: "",
    notIncluded: "",
    additionalInformation: "",
    cancelation: "",
    cancellationPolicyId: "",
    capacity: "",
    maxParticipants: "",
    featureTour: 0,
    specialTour: 0,
    splTourToBMM: 0,
    personalizedCategory: {
      personalizeCategoryName: "",
      tagIdentifiers: [],
    },
    question: "",
    themeId: "",
    categoryId: "",
    subCategoryId: "",
    latitude: "",
    longitude: "",
    address: "",
    price: 0,
    currency: "",
    placeId: "",
  });

  useEffect(() => {
    const options = cc.codes().map((code) => ({
      value: code,
      label: `${code}`,
    }));
    setCurrencyOptions(options);
  }, []);
// Ensure themes are loaded before setting themeId
useEffect(() => {
  fetchCancellationPolicies();
  // fetchThemes();
  fetchPersonalizedSubCategories();

}, []);

// useEffect(() => {
//   if (themes.length > 0 && tour) {
//     setTourData((prevData) => ({
//       ...prevData,
//       themeId: tour.tourTheme[0]?.tourThemeId || "",
//     }));
//   }
// }, [themes, tour]);

  useEffect(() => {
    fetchThemes();

    console.log("tour---------------->",tour)
    if (tour) {
      setTourData({
        ...tour,
        address: tour.location?.address || "",
        latitude: tour.location?.latitude || "",
        longitude: tour.location?.longitude || "",
        placeId: tour.location?.placeId || "",
        currency: tour.currency || "",
        themeId: "b10108f4-5aab-4079-bb37-dbd603254282" || "", // Set the themeId
        categoryId: tour.tourTheme[0]?.category?.categoryId || "", // Set the categoryId
        subCategoryId: tour.tourTheme[0]?.subCategory?.subCategoryId || "", // Set the subCategoryId
        personalizedCategory: {
          personalizeCategoryName:
            tour.personalizedCategory?.personalizeCategoryName || "",
          tagIdentifiers: tour.personalizedCategory?.tagIdentifiers || [],
        },
      });
      console.log("tour.tourTheme[0]",tour.tourTheme[0]?.tourThemeId)
      console.log("tourdata-------------------->",tourData.themeId)
      setAddress(tour.location?.address || "");
    }
  }, [tour]);


// Fetch categories when themeId changes
useEffect(() => {
  if (tourData.themeId) {
    fetchCategoriesByTheme(tourData.themeId);
  } else {
    setCategories([]);
  }
}, [tourData.themeId]);

// Fetch subcategories when categoryId changes
useEffect(() => {
  if (tourData.categoryId) {
    fetchSubCategories(tourData.categoryId);
  } else {
    setSubCategories([]);
  }
}, [tourData.categoryId]);



  const fetchCategoriesByTheme = async (themeId) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/category/theme/${themeId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories by theme:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/subcategory/category/${categoryId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const getQuestion = async (name) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/questions/personalized-category/${name}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();

      setTourData((prevData) => ({
        ...prevData,
        personalizedCategory: {
          ...prevData.personalizedCategory,
          personalizeCategoryName: data.data.name,
        },
      }));

      // Set questions data
      setQuestionsData(data.data.question);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const gettagIdentifiersByQuestionId = async (questionId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/tagIdentifiers/personalizedCategory/${questionId}`
      );
      if (!response.ok) {
        throw new Error("Error fetching all tours: ${response.statusText}");
      }
      const data = await response.json();
      setTagIdentifiers(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "personalizeCategoryName") {
      setTourData((prevData) => ({
        ...prevData,
        personalizedCategory: {
          ...prevData.personalizedCategory,
          personalizeCategoryName: value,
        },
      }));
    } else {
      setTourData((prevData) => ({
        ...prevData,
        [name]: ["featureTour", "specialTour", "splTourToBMM"].includes(name)
          ? parseInt(value)
          : value,
      }));
    }

    if (name === "themeId") {
      if (value) {
        fetchCategoriesByTheme(value);
      } else {
        setCategories([]);
        setSubCategories([]);
      }
    } else if (name === "categoryId") {
      if (value) {
        fetchSubCategories(value);
      } else {
        setSubCategories([]);
      }
    }

    if (name === "personlizedSubCategory") {
      getQuestion(value);
      setQuestionsDropdown(true);
    }
    if (name === "question") {
      gettagIdentifiersByQuestionId(value);
      setTagIdentifiersDropdown(true);
    }
  };

  const handleTextEditorChange = (value, name) => {
    const encodedHtml = he.encode(value, {
      useNamedReferences: true,
    });
    setTourData((prevData) => ({
      ...prevData,
      [name]: encodedHtml,
    }));
  };

  const handleAddressChange = (value) => setAddress(value);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setTourData((prevData) => ({
      ...prevData,
      address: value,
      latitude: latLng.lat,
      longitude: latLng.lng,
      placeId: results[0].place_id,
    }));
  };

  const handleAddTagIdentifier = () => {
    const newEntry = {
      category: tourData.personalizedCategory.personalizeCategoryName,
      question:
        questionsData.find(
          (question) => question.questionId === tourData.question
        )?.question || "",
      tagIdentifier: tourData.tagIdentifier,
    };

    if (newEntry.category && newEntry.question && newEntry.tagIdentifier) {
      setSelectedTagIdentifiers([...selectedTagIdentifiers, newEntry]);

      setTourData({
        ...tourData,
        personalizedCategory: { personalizeCategoryName: "" },
        question: "",
        tagIdentifier: "",
      });
    } else {
      toast.error("Please select all fields to add a tag identifier");
    }
  };

  const handleRemoveTagIdentifier = (index) => {
    const updatedTags = [...selectedTagIdentifiers];
    updatedTags.splice(index, 1);
    setSelectedTagIdentifiers(updatedTags);
  };

  // const handleAddTagIdentifier = () => {
  //   if (tourData.tagIdentifier) {
  //     setSelectedTagIdentifiers((prev) => [...prev, tourData.tagIdentifier]);
  //     setTourData((prevData) => ({
  //       ...prevData,
  //       tagIdentifier: "",
  //     }));
  //     toast.success("Tag Identifier added!");
  //   }
  // };

  // const handleRemoveTagIdentifier = (index) => {
  //   const updatedTagIdentifiers = selectedTagIdentifiers.filter(
  //     (_, i) => i !== index
  //   );
  //   setSelectedTagIdentifiers(updatedTagIdentifiers);
  // };

  const fetchCancellationPolicies = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/cancellationPolicy`);
      const data = await response.json();
      setCancellationPolicies(data.data);
       if (tour && tour.cancellationPolicy) {
      const selectedPolicy = data.data.find(
        (policy) => policy.policyName === tour.cancellationPolicy
      );

      if (selectedPolicy) {
        // Set the cancellationPolicyId in tourData to ensure it's selected in the dropdown
        setTourData((prevData) => ({
          ...prevData,
          cancellationPolicyId: selectedPolicy.cancellationPolicyId,
        }));
      }
    }
      console.log("data.======>",data.data)
    } catch (error) {
      console.error("Error fetching cancellation policies:", error);
    }
  };

  
  const fetchThemes = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/themes`);
      const data = await response.json();
      setThemes(data.data);


      
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const fetchPersonalizedSubCategories = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/personalized-category`);
      const data = await response.json();
      setPersonalizedSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching personalized subcategories:", error);
    }
  };

  const handleAddThemeCategorySubcategory = () => {
    const selectedTheme = themes?.find(
      (theme) => theme?.themeId == tourData?.themeId
    );
    const selectedCategory = categories
      ? categories.find((cat) => cat.categoryId == tourData.categoryId)
      : null;
    const selectedSubCategory = subCategories
      ? subCategories.find((sub) => sub.subCategoryId == tourData.subCategoryId)
      : null;
    let newSelection = {};

    if (selectedTheme && !selectedCategory && !selectedSubCategory) {
      newSelection = {
        themeId: selectedTheme?.themeId,
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        selectionDepth: "theme",
      };
    } else if (selectedTheme && selectedCategory && !selectedSubCategory) {
      newSelection = {
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        categoryId: selectedCategory.categoryId,
        categoryName: selectedCategory.categoryName,
        selectionDepth: "category",
      };
    } else if (selectedTheme && selectedCategory && selectedSubCategory) {
      newSelection = {
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        categoryId: selectedCategory.categoryId,
        categoryName: selectedCategory.categoryName,
        subCategoryId: selectedSubCategory.subCategoryId,
        subCategoryName: selectedSubCategory.subCategoryName,
        selectionDepth: "subcategory",
      };
    }
    if (Object.keys(newSelection).length !== 0) {
      const updatedSelections = [...selections, newSelection];
      console.log("updatedselection", updatedSelections);
      setSelections(updatedSelections);
      setTourData((prevData) => ({
        ...prevData,
        themes: updatedSelections.map((selection) => ({
          themeId: selection.themeId,
          categoryId: selection.categoryId,
          subCategoryId: selection.subCategoryId,
        })),
      }));
      setTourData((prevData) => ({
        ...prevData,
        themeId: "",
        categoryId: "",
        subCategoryId: "",
      }));
    }
  };

  const renderThemeSelections = () => {
    return (
      selections &&
      Array.isArray(selections) &&
      selections.map(
        (selection, index) =>
          selection.themeName && (
            <div
              key={index}
              className="selection-tag-wrapper d-flex align-items-center "
            >
              <div className="selection-tag">{selection.themeName}</div>
              <DashCircle
                className="dash-icon-container ms-2"
                onClick={() => handleRemoveSelection(index)}
              />
            </div>
          )
      )
    );
  };

  const renderCategorySelections = () => {
    return (
      selections &&
      Array.isArray(selections) &&
      selections.map(
        (selection, index) =>
          selection.categoryName && (
            <div key={index} className="selection-tag-wrapper">
              <div className="selection-tag">{selection.categoryName}</div>
            </div>
          )
      )
    );
  };

  const renderSubCategorySelections = () => {
    return (
      selections &&
      Array.isArray(selections) &&
      selections.map(
        (selection, index) =>
          selection.subCategoryName && (
            <div
              key={index}
              className="selection-tag-wrapper d-flex align-items-center"
            >
              <div className="selection-tag">{selection.subCategoryName}</div>
              <DashCircle
                className="dash-icon-container ms-2"
                onClick={() => handleRemoveSelection(index)}
              />
            </div>
          )
      )
    );
  };

  const handleRemoveSelection = (index) => {
    setSelections((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const updatedTourData = {
      title: tourData.title,
      shortDescription: he.decode(tourData.shortDescription),
      description: he.decode(tourData.description),
      maxParticipants: tourData.maxParticipants,
      cancelation: tourData.cancelation,
      cancellationPolicyId: tourData.cancellationPolicyId,
      highlights: he.decode(tourData.highlights),
      included: he.decode(tourData.included),
      notIncluded: he.decode(tourData.notIncluded),
      additionalInformation: he.decode(tourData.additionalInformation),
      latitude: parseFloat(tourData.latitude),
      longitude: parseFloat(tourData.longitude),
      placeId: tourData.placeId || "",
      address: tourData.address,
      price: parseFloat(tourData.price),
      currency: tourData.currency,
      capacity: tourData.capacity,
      featureTour: tourData.featureTour,
      specialTour: tourData.specialTour,
      splTourToBMM: tourData.splTourToBMM,
      personalizedCategory: {
        personalizeCategoryName:
          tourData.personalizedCategory.personalizeCategoryName,
        tagIdentifiers: selectedTagIdentifiers,
      },
      associateCategories: selections.map((selection) => ({
        themeId: selection.themeId,
        categoryId: selection.categoryId,
        subCategoryId: selection.subCategoryId,
      })),
    };

    try {
      const response = await fetch(
        `${BaseAPI}/tour/update/tour/${tour.tourId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedTourData),
        }
      );

      if (!response.ok) throw new Error("Failed to update tour");

      const updatedData = await response.json();
      toast.success("Tour updated successfully!");
      handleUpdateTour(updatedData);
      onCloseAndRefresh();
    } catch (error) {
      // toast.error("Error updating tour");
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setTourData((prevData) => ({
      ...prevData,
      currency: selectedOption.value,
    }));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px",
      borderRadius: "2px",
      border: "1px solid #ced4da",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      padding: "8px 12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      overflow: "hidden",
      zIndex: 9999,
    }),
  };

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CurrencyFlag currency={value} size="lg" style={{ marginRight: 10 }} />
      {label}
    </div>
  );

  

  return (
    <Container className=" ms-1 font-common">
      <Row className="justify-content-center">
        <Col lg={11} md={10}>
          <h4 className=" mb-2 font-common">Edit Tour</h4>
          <Card>
            <Card.Body>
              <Form onSubmit={handleUpdate}>
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter tour name"
                    name="title"
                    value={tourData.title}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Short Description</Form.Label>
                  <ReactQuill
                    value={he.decode(tourData.shortDescription)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "shortDescription")
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <ReactQuill
                    value={he.decode(tourData.description)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "description")
                    }
                  />
                </Form.Group>

                <Form.Group className="mt-2 position-relative">
                  <Form.Label>Search Location</Form.Label>
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleAddressChange}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <Form.Control
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => (
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}
                              style={{ cursor: "pointer" }}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Form.Group>

                <Row className="mb-3 mt-4">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Latitude</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Latitude"
                        name="latitude"
                        value={tourData.latitude}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Longitude</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Longitude"
                        name="longitude"
                        value={tourData.longitude}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Additional Information</Form.Label>
                  <ReactQuill
                    value={he.decode(tourData.additionalInformation)}
                    onChange={(value) =>
                      handleTextEditorChange(value, "additionalInformation")
                    }
                  />
                </Form.Group>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Max Participants</Form.Label>
                      <Form.Control
                        type="number"
                        name="maxParticipants"
                        value={tourData.maxParticipants}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Capacity</Form.Label>
                      <Form.Control
                        type="number"
                        name="capacity"
                        value={tourData.capacity}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="4">
                    <Form.Group>
                      <Form.Label>Feature Tour</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="featureTour"
                          value={1}
                          checked={tourData.featureTour === 1}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="featureTour"
                          value={0}
                          checked={tourData.featureTour === 0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group>
                      <Form.Label>Special Tour</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="specialTour"
                          value={1}
                          checked={tourData.specialTour === 1}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="specialTour"
                          value={0}
                          checked={tourData.specialTour === 0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <Form.Group>
                      <Form.Label>Special BMM Tour</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="splTourToBMM"
                          value={1}
                          checked={tourData.splTourToBMM === 1}
                          onChange={handleInputChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="splTourToBMM"
                          value={0}
                          checked={tourData.splTourToBMM === 0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Cancellation</Form.Label>
                      <Form.Control
                        type="text"
                        name="cancelation"
                        placeholder="Enter tour cancellation Days"
                        value={tourData.cancelation}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Cancellation Policy</Form.Label>
                      <Form.Control
                        as="select"
                        name="cancellationPolicyId"
                        value={tourData.cancellationPolicyId}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Cancellation Policy</option>
                        {cancellationPolicies.map((policy) => (
                          <option
                            key={policy.cancellationPolicyId}
                            value={policy.cancellationPolicyId}
                          >
                            {policy.policyName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={6} className="mb-3">
                    <Form.Label>Price</Form.Label>
                    <InputGroup>
                      <div style={{ width: "35%" }}>
                        <Select
                          id="currency"
                          name="currency"
                          options={currencyOptions}
                          value={currencyOptions.find(
                            (option) => option.value === tourData.currency
                          )}
                          onChange={handleSelectChange}
                          placeholder="currency"
                          styles={customStyles}
                          formatOptionLabel={formatOptionLabel}
                        />
                      </div>
                      <Form.Control
                        type="number"
                        id="price"
                        name="price"
                        placeholder="Enter Price"
                        value={tourData.price}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                </Row> */}

                <Row className="mb-3 mt-2">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Select Personalized Categories</Form.Label>
                      <Form.Select
                        name="personalizedCategory"
                        value={
                          tourData.personalizedCategory.personalizeCategoryName
                        }
                        onChange={(e) => {
                          handleInputChange(e);
                          getQuestion(e.target.value);
                        }}
                      >
                        <option value="">Select personalized category</option>
                        {personalizedSubCategories.map((subCat) => (
                          <option key={subCat.id} value={subCat.name}>
                            {subCat.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={8}>
                    <Form.Group>
                      <Form.Label>
                        Select Question and Tag Identifier
                      </Form.Label>
                      <div className="d-flex align-items-center ">
                        <Form.Select
                          name="question"
                          value={tourData.question}
                          onChange={handleInputChange}
                          className="me-2"
                          style={{ flex: "1" }}
                        >
                          <option value="">Select Question</option>
                          {Array.isArray(questionsData) &&
                            questionsData.map((question) => (
                              <option
                                key={question.questionId}
                                value={question.questionId}
                              >
                                {question.question}
                              </option>
                            ))}
                        </Form.Select>

                        <CreatableSelect
                                      name="tagIdentifier"
                                      placeholder="Select or create Tag Identifier"
                                      value={
                                        tourData.tagIdentifier
                                          ? tourData.tagIdentifier.map(
                                              (tag) => ({
                                                value: tag,
                                                label: tag,
                                              })
                                            )
                                          : []
                                      }
                                      onChange={(selectedOptions) =>
                                        {console.log("selectedOptions===>",selectedOptions);
                                        setTourData({
                                          ...tourData,
                                          tagIdentifier: selectedOptions
                                            ? selectedOptions.map(
                                                (option) => option.value
                                              )
                                            : [],
                                        })
                                      }
                                      }
                                      options={tagIdentifiers.map((tag) => ({
                                        value: tag.tagIdentifier,
                                        label: tag.tagIdentifier,
                                      }))}
                                      isMulti
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          minHeight: "40px",
                                          height: "auto",
                                          borderRadius: "0.375rem",
                                          borderColor: state.isFocused
                                            ? "#5852F7"
                                            : "#ccc",
                                          boxShadow: "none",
                                          "&:hover": {
                                            borderColor: state.isFocused
                                              ? "#5852F7"
                                              : "#A0A3BD",
                                          },
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          height: "auto",
                                          padding: "0 6px",
                                          flexWrap: "wrap",
                                        }),
                                        multiValue: (provided) => ({
                                          ...provided,
                                          backgroundColor: "#F6F7FE",
                                          borderRadius: "15px",
                                          padding: "1px 5px",
                                          margin: "4px",
                                          maxWidth: "none",
                                        }),
                                        multiValueLabel: (provided) => ({
                                          ...provided,
                                          color: "#000",
                                          whiteSpace: "normal",
                                        }),
                                        multiValueRemove: (provided) => ({
                                          ...provided,
                                          color: "#000",
                                          marginTop: "3px",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                            color: "#000",
                                          },
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isFocused
                                            ? "#F6F7FE"
                                            : state.isSelected
                                            ? "#F6F7FE"
                                            : "#fff",
                                          color: "#000",
                                          "&:hover": {
                                            backgroundColor: "#F6F7FE",
                                          },
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          backgroundColor: "#F6F7FE",
                                        }),
                                        menuList: (provided) => ({
                                          ...provided,
                                          maxHeight: "100px",
                                          overflowY: "auto",
                                        }),
                                      }}
                                    />

                        <PlusCircle
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                          onClick={handleAddTagIdentifier}
                        />
                      </div>

                      {selectedTagIdentifiers.length > 0 && (
                        <div className="mt-2">
                          <ul className="list-unstyled">
                            {selectedTagIdentifiers.map((entry, index) => (
                              <li
                                key={index}
                                className="d-flex align-items-center"
                              >
                                <div className="me-2">
                                  {entry.category} - {entry.question} -{" "}
                                  {entry.tagIdentifier}
                                </div>
                                <DashCircle
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleRemoveTagIdentifier(index)
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <div className="row">
                    <Col md={4}>
                      <div className="select-wrapper">
                        <Form.Label className="form-label">
                          Select Theme
                        </Form.Label>
                        {console.log("tourData-----------------.tourData",tourData.themeId)}
                        <Form.Control
  as="select"
  name="themeId"
  value={tourData.themeId || ""}
  onChange={handleInputChange}
>
  <option value="">Select a Theme</option>
  {themes.map((theme) => (
    <option key={theme.themeId} value={theme.themeId}>
      {theme.themeName}
    </option>
  ))}
</Form.Control>

                        <div className="selection-names-container">
                          {renderThemeSelections()}
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="select-wrapper">
                        <Form.Label className="form-label">Category</Form.Label>
                        <Form.Control
                          as="select"
                          name="categoryId"
                          value={tourData.categoryId}
                          onChange={handleInputChange}
                        >
                          <option value="">Select a Category</option>
                          {categories &&
                            Array.isArray(categories) &&
                            categories.map((category) => (
                              <option
                                key={category.categoryId}
                                value={category.categoryId}
                              >
                                {category.categoryName}
                              </option>
                            ))}
                        </Form.Control>
                        <div className="selection-names-container">
                          {renderCategorySelections()}
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="select-wrapper">
                        <Form.Label className="form-label">
                          Subcategory
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            as="select"
                            name="subCategoryId"
                            value={tourData.subCategoryId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select a Subcategory</option>
                            {subCategories &&
                              Array.isArray(subCategories) &&
                              subCategories.map((subCategory) => (
                                <option
                                  key={subCategory.subCategoryId}
                                  value={subCategory.subCategoryId}
                                >
                                  {subCategory.subCategoryName}
                                </option>
                              ))}
                          </Form.Control>
                          <PlusCircle
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                            onClick={handleAddThemeCategorySubcategory}
                            className="mt-2 ms-2 "
                          />
                        </div>
                        <div className="selection-names-container">
                          {renderSubCategorySelections()}
                        </div>
                      </div>
                    </Col>
                  </div>
                </Row>

                <div className="d-flex justify-content-center mt-4 mb-2">
                  <Button type="submit" variant="primary" className="me-2">
                    Update Tour
                  </Button>
                  <Button variant="secondary" onClick={handleCancelEdit}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditTour;
