import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import Navbar from "../common/navbar";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

  const navigate = useNavigate();

  const handleTourClick = (e) => {
    e.preventDefault();
    navigate("/create-tour");
  };

  const handleScheduledTourClick = (e) => {
    e.preventDefault();
    navigate("/scheduled-tour");
  };

  const handleBookedTourClick = (e) => {
    e.preventDefault();
    navigate("/booked-tour");
  };

  const handleItineraryTourClick = (e) => {
    e.preventDefault();
    navigate("/create-itinerary");
  };

  

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  // Fetch the count from the API when the component mounts
  // useEffect(() => {
  //   fetchStats();
  // }, []);

  // const fetchStats = async () => {
  //   try {
  //     const response = await fetch(`${BaseUrl}/rest/auth/count`
  //     );
  //     if (!response.ok) throw new Error("Network response was not ok");
  //     const data = await response.json();
  //     localStorage.setItem("token", data.token);
  //     setStats({
  //       totalBookings: data.totalBookings,
  //       totalTours: data.totalTours,
  //       countScheduleTours: data.countScheduleTours,
  //     });
  //   } catch (error) {
  //     console.error("Error fetching stats:", error);
  //   }
  // };

  return (
    <>
      <div>
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  {/* <h6 className="text-muted mb-1">Welcome back,</h6> */}
                  {/* <h5 className="mb-0">Vendor Panel</h5> */}
                </div>
              </div>

              <div className="row ms-2 me-2">
                <div className="col mt-4"  onClick={handleTourClick}>
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-map fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3" >
                        <h6 className="mb-0 text-muted">Tour</h6>
                        
                      </div>
                    </div>

                  </span>
                </div>

                <div className="col mt-4" onClick={handleScheduledTourClick}>
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-calendar-check fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted">Scheduled Tour</h6>
                       </div>
                    </div>

                  </span>
                </div>

                <div className="col mt-4"onClick={handleBookedTourClick}>
                  <span className="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-pill">
                        <i className="mdi mdi-ticket fs-4 mb-0"></i>
                      </div>
                      <div className="flex-1 ms-3">
                        <h6 className="mb-0 text-muted">Booked Tour</h6>
                      
                      </div>
                    </div>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
