import React from "react";
import { Modal, Row, Col, Button ,Form} from "react-bootstrap";

const TourView = ({ show, handleClose, selectedTourForView }) => {
  console.log("selectedtour",selectedTourForView)

  const getLocation = (index, field) => {
    return selectedTourForView?.location && selectedTourForView.location[index]
      ? selectedTourForView.location[index][field] || ""
      : "";
  };

  return (
    <Modal className="modal-lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>View Tour</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedTourForView && (
          <Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={selectedTourForView.title}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={selectedTourForView.description}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  type="text"
                  name="shortDescription"
                  value={selectedTourForView.shortDescription}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Highlights</Form.Label>
                <Form.Control
                  type="text"
                  name="highlights"
                  value={selectedTourForView.highlights}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Max Participants</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedTourForView.maxParticipants}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Theme</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    selectedTourForView.themes && selectedTourForView.themes[0]
                      ? selectedTourForView.themes[0].themeName
                      : ""
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    selectedTourForView.tourTheme &&
                    selectedTourForView.tourTheme[0]
                      ? selectedTourForView.tourTheme[0].category.categoryName
                      : ""
                  }
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Subcategory</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    selectedTourForView.tourTheme &&
                    selectedTourForView.tourTheme[0]
                      ? selectedTourForView.tourTheme[0].subCategory
                          .subCategoryName
                      : ""
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  type="text"
                  name="durationdays"
                  value={selectedTourForView.duration}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Duration Time</Form.Label>
                <Form.Control
                  type="text"
                  name="durationTime"
                  value={selectedTourForView.durationTime}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  name="price"
                  value={selectedTourForView.price}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Currency</Form.Label>
                <Form.Control
                  type="text"
                  name="currency"
                  value={selectedTourForView.currency}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Whats Included</Form.Label>
                <Form.Control
                  type="text"
                  name="included"
                  value={selectedTourForView.included}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Whats Not Included</Form.Label>
                <Form.Control
                  type="text"
                  name="notIncluded"
                  value={selectedTourForView.notIncluded}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  name="latitude"
                  value={getLocation(0, "latitude")}
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  name="longitude"
                  value={getLocation(0, "longitude")}
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Additional Information</Form.Label>
                <Form.Control
                  type="text"
                  name="additionalInformation"
                  value={selectedTourForView.additionalInformation}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Cancellation Policy</Form.Label>
                <Form.Control
                  type="text"
                  name="cancellationPolicy"
                  value={selectedTourForView.cancellationPolicy}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formTourName">
                <Form.Label>Cancellation</Form.Label>
                <Form.Control
                  type="text"
                  name="cancelation"
                  value={selectedTourForView.cancelation}
                  className="w-50"
                />
              </Form.Group>
            </Row>
          </Row>
        )}
      </Modal.Body>
      <Button variant="btn btn-secondary w-25 close mb-3 ms-3" onClick={handleClose}>
        Close
      </Button>
    </Modal>
  );
};

export default TourView;
