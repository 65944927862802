import React, { useState, useEffect, useRef } from "react";
import Navbar from "../common/navbar";
import Sidebar from "../sidebar/sidebar";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
  Modal,
} from "react-bootstrap";
import {
  PlusCircle,
  DashCircle,
  BoxArrowInUp,
  EyeFill,
  PencilFill,
  Trash3,
} from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";

import he from "he";
import { useSelector } from "react-redux";
import TourView from "./TourView";
import TourEdit from "./TourEdit";
import LocationForm from "./Location";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import TourDetails from "./TourDetails";
import cc from "currency-codes";
import CreatableSelect from "react-select/creatable";

function TourCreate() {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const BaseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [snapshotImages, setSnapshotImages] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [displayedFile, setDisplayedFile] = useState([]);
  const [selectedSnapshotsFile, SetselectedSnapshotsFile] = useState([]);
  const [displayedSnapshotFiles, setDisplayedSnapshotFiles] = useState([]);
  const fileInputRefFeatured = useRef(null);
  const fileInputRefSnapshots = useRef(null);
  const [themes, setThemes] = useState([]);
  const [allTours, setAllTours] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [locationFormTourId, setLocationFormTourId] = useState(null);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState({});
  const [cancellationPolicyId, setcancellationPolicyId] = useState({});
  const [selectedTourForView, setSelectedTourForView] = useState(null);
  const [selectedTourForEdit, setSelectedTourForEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [address, setAddress] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [uploadImage, setUploadImge] = useState(false);
  const [imageType, setImageType] = useState({});
  const { userData, token } = useSelector((state) => state);
  const [selections, setSelections] = useState([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showEditPopup1, setShowEditPopup1] = useState(false);

  const [selectedFile, setSelectedFile] = useState([]);
  const [personlizedSubCategory, setPersonlizedSubCategory] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [tagIdentifiers, setTagIdentifiers] = useState([]);
  const [questionDropDown, setQuestionsDropdown] = useState(false);
  const [tagIdentifiersDropDown, setTagIdentifiersDropdown] = useState(false);
  const [selectedTagIdentifiers, setSelectedTagIdentifiers] = useState([]);
  const [allCurrency, setAllCurrency] = useState([]);
  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [fullDescription, setFullDescription] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [tourData, setTourData] = useState({
    vendorId: "",
    title: "",
    description: "",
    maxParticipants: 0,
    price: 0,
    currency: "",
    address: "",
    latitude: 0,
    longitude: 0,
    highlights: "",
    included: "",
    notIncluded: "",
    additionalInformation: "",
    shortDescription: "",
    cancelation: "",
    personlizedSubCategory: "",
    capacity: "",
    tagIdentifier: [],
    question: "",
    themes: [],
    snapshotsImages: [],
    featureTour: 0,
    specialTour: 0,
    splTourToBMM: 0,
  });

  const initialTourData = {
    vendorId: "",
    title: "",
    description: "",
    maxParticipants: 0,
    price: 0,
    currency: "",
    address: "",
    latitude: 0,
    longitude: 0,
    highlights: "",
    included: "",
    notIncluded: "",
    additionalInformation: "",
    shortDescription: "",
    cancelation: "",
    personlizedSubCategory: "",
    capacity: "",
    tagIdentifier: [],
    question: "",
    themes: [],
    snapshotsImages: [],
    featureTour: 0,
    specialTour: 0,
    splTourToBMM: 0,
  };

  const resetForm = () => {
    setTourData(initialTourData);
    setAddress("");
    setSelections([]);
    setSelectedTagIdentifiers([]);
  };

  useEffect(() => {
    const currencies = cc.codes().map((code) => {
      const currency = cc.code(code);
      return {
        name: currency.name,
        code: currency.code,
      };
    });
    setCurrencyList(currencies);
  }, []);

  const getCurrencyOptions = () => {
    return cc.codes().map((code) => ({
      label: ` ${code}`,
      value: code,
    }));
  };

  useEffect(() => {
    setCurrencyOptions(getCurrencyOptions());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTheme();
    getPersonlizedSubCategory();
    getAllCountry();
    getCancellationPolicy();

    if (window.google && window.google.maps) {
      setIsGoogleMapsLoaded(true);
    }
  }, []);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    setAddress(value);

    setTourData({
      ...tourData,
      latitude: latLng.lat,
      longitude: latLng.lng,
      placeId: results[0].place_id,
      address: value,
    });
  };

  const handleCloseLocationModal = () => {
    setShowLocationModal(false);
  };

  const handleRemoveSelection = (index) => {
    setSelections((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleAddThemeCategorySubcategory = () => {
    const selectedTheme = themes?.find(
      (theme) => theme?.themeId == tourData?.themeId
    );
    const selectedCategory = categories
      ? categories.find((cat) => cat.categoryId == tourData.categoryId)
      : null;
    const selectedSubCategory = subCategories
      ? subCategories.find((sub) => sub.subCategoryId == tourData.subCategoryId)
      : null;
    let newSelection = {};

    if (selectedTheme && !selectedCategory && !selectedSubCategory) {
      newSelection = {
        themeId: selectedTheme?.themeId,
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        selectionDepth: "theme",
      };
    } else if (selectedTheme && selectedCategory && !selectedSubCategory) {
      newSelection = {
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        categoryId: selectedCategory.categoryId,
        categoryName: selectedCategory.categoryName,
        selectionDepth: "category",
      };
    } else if (selectedTheme && selectedCategory && selectedSubCategory) {
      newSelection = {
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        themeId: selectedTheme?.themeId,
        themeName: selectedTheme?.themeName,
        categoryId: selectedCategory.categoryId,
        categoryName: selectedCategory.categoryName,
        subCategoryId: selectedSubCategory.subCategoryId,
        subCategoryName: selectedSubCategory.subCategoryName,
        selectionDepth: "subcategory",
      };
    }
    if (Object.keys(newSelection).length !== 0) {
      const updatedSelections = [...selections, newSelection];
      console.log("updatedselection", updatedSelections);
      setSelections(updatedSelections);
      setTourData((prevData) => ({
        ...prevData,
        themes: updatedSelections.map((selection) => ({
          themeId: selection.themeId,
          categoryId: selection.categoryId,
          subCategoryId: selection.subCategoryId,
        })),
      }));
      setTourData((prevData) => ({
        ...prevData,
        themeId: "",
        categoryId: "",
        subCategoryId: "",
      }));
    }
  };

  const renderThemeSelections = () => {
    return (
      selections &&
      Array.isArray(selections) &&
      selections.map(
        (selection, index) =>
          selection.themeName && (
            <div
              key={index}
              className="selection-tag-wrapper d-flex align-items-center "
            >
              <div className="selection-tag">{selection.themeName}</div>
              <DashCircle
                className="dash-icon-container ms-2"
                onClick={() => handleRemoveSelection(index)}
              />
            </div>
          )
      )
    );
  };

  const renderCategorySelections = () => {
    return (
      selections &&
      Array.isArray(selections) &&
      selections.map(
        (selection, index) =>
          selection.categoryName && (
            <div key={index} className="selection-tag-wrapper">
              <div className="selection-tag">{selection.categoryName}</div>
            </div>
          )
      )
    );
  };

  const renderSubCategorySelections = () => {
    return (
      selections &&
      Array.isArray(selections) &&
      selections.map(
        (selection, index) =>
          selection.subCategoryName && (
            <div
              key={index}
              className="selection-tag-wrapper d-flex align-items-center"
            >
              <div className="selection-tag">{selection.subCategoryName}</div>
              <DashCircle
                className="dash-icon-container ms-2"
                onClick={() => handleRemoveSelection(index)}
              />
            </div>
          )
      )
    );
  };

  const fetchTheme = async () => {
    try {
      const response = await fetch(` ${BaseAPI}/tour/all/themes`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setThemes(data.data);
      setIsloading(false);
    } catch (error) {
      setError(error.toString());
      setIsloading(false);
      console.error("Error fetching themes:", error);
    }
  };

  const fetchCategoriesByTheme = async (themeId) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/category/theme/${themeId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data.data);
    } catch (error) {
      console.error("Error fetching categories by theme:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/subcategory/category/${categoryId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("theme", name, value);
    setTourData((prevData) => ({
      ...prevData,
      [name]: ["featureTour", "specialTour", "splTourToBMM"].includes(name)
        ? parseInt(value)
        : value,
    }));
    if (name === "themeId") {
      if (value) {
        fetchCategoriesByTheme(value);
      } else {
        setCategories([]);
        setSubCategories([]);
      }
    } else if (name === "categoryId") {
      if (value) {
        fetchSubCategories(value);
      } else {
        setSubCategories([]);
      }
    }
    if (name === "personlizedSubCategory") {
      getQuestion(value);
      setQuestionsDropdown(true);
    }
    if (name === "question") {
      gettagIdentifiersByQuestionId(value);
      setTagIdentifiersDropdown(true);
    }
  };

  const handleInputTextChange = (value, name) => {
    const encodedHtml = he.encode(value, {
      useNamedReferences: true,
    });
    setTourData((prevData) => ({
      ...prevData,
      [name]: encodedHtml,
    }));
  };

  const handleCancellationChange = (e) => {
    setcancellationPolicyId(e.target.value);
  };

  const handleAddTagIdentifier = () => {
    if (tourData.tagIdentifier) {
      setSelectedTagIdentifiers((prev) => [...prev, tourData.tagIdentifier]);
      setTourData((prevData) => ({
        ...prevData,
        tagIdentifier: "",
      }));
      toast.success("TagIdentifier added !!");
    }
  };

  useEffect(() => {
    const selectedTheme = themes?.find(
      (theme) => theme?.themeId == tourData?.themeId
    );
    const selectedCategory =
      categories?.find(
        (category) => category.categoryId == tourData.categoryId
      ) ?? null;
    const selectedSubCategory = subCategories?.find(
      (subCategory) => subCategory.subCategoryId == tourData.subCategoryId
    );

    if (selectedTheme && selectedCategory && selectedSubCategory) {
      const newThemes = [
        {
          themeId: selectedTheme?.themeId,
          themeId: selectedTheme?.themeId,
          themeName: selectedTheme?.themeName,
          categoryId: selectedCategory.categoryId,
          categoryName: selectedCategory.categoryName,
          subCategoryId: selectedSubCategory.subCategoryId,
          subCategoryName: selectedSubCategory.subCategoryName,
        },
      ];
      setTourData((prevData) => ({
        ...prevData,
        themes: [newThemes],
      }));
    }
  }, [
    themes,
    categories,
    subCategories,
    tourData?.themeId,
    tourData.categoryId,
    tourData.subCategoryId,
  ]);

  const validate = () => {
    const newErrors = {};

    if (!tourData.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!tourData.shortDescription.trim()) {
      newErrors.shortDescription = "Short Description is required";
    }
    if (!tourData.description.trim()) {
      newErrors.description = "Description is required";
    }
    if (!tourData.highlights.trim()) {
      newErrors.highlights = "Highlights are required";
    }
    if (!tourData.address.trim()) {
      newErrors.address = "Search Location is required";
    }
    if (!tourData.maxParticipants) {
      newErrors.maxParticipants = "Max Participants is required";
    }
    if (!tourData.capacity) {
      newErrors.capacity = "Max Capacity is required";
    }
    if (!tourData.cancelation.trim()) {
      newErrors.cancelation = "Cancellation is required";
    }
    if (!cancellationPolicyId) {
      newErrors.cancellationPolicyId = "Cancellation Policy is required";
    }

    return newErrors;
  };

  const handleCreateTour = (e) => {
    e.preventDefault();

    const formErrors = validate();
    console.log(formErrors);
    if (Object.keys(formErrors).length > 0) {
      toast.warn(formErrors);

      setErrors(formErrors);
      setIsloading(false);

      return;
    }

    setErrors({});

    setIsloading(true);

    if (
      (tourData.themeId || tourData.categoryId || tourData.subCategoryId) &&
      selections.length === 0
    ) {
      alert("Please add the selection before creating the tour.");
      return;
    }
console.log("toudata----------->",tourData.tagIdentifier)
    const updatedTourData = {
      ...tourData,
      placeId,
      themes: selections.map((selection) => ({
        themeId: selection?.themeId,
        categoryId: selection.categoryId,
        subCategoryId: selection.subCategoryId,
      })),
      // tagIdentifier: selectedTagIdentifiers, // Add tagIdentifier to the payload here

    };

    const tourDataJson = JSON.stringify({
      title: updatedTourData.title,
      description: updatedTourData.description,
      maxParticipants: parseInt(updatedTourData.maxParticipants),
      // currency: updatedTourData.currency,
      address: updatedTourData.address,
      latitude: updatedTourData.latitude,
      longitude: updatedTourData.longitude,
      highlights: updatedTourData.highlights,
      capacity: updatedTourData.capacity,
      additionalInformation: updatedTourData.additionalInformation,
      cancellationPolicyId: cancellationPolicyId,
      cancelation: updatedTourData.cancelation,
      shortDescription: updatedTourData.shortDescription,
      included: updatedTourData.included,
      notIncluded: updatedTourData.notIncluded,
      themes: updatedTourData.themes,
      personalizedCategory: {
        personalizeCategoryName: updatedTourData.personlizedSubCategory,
        tagIdentifiers: updatedTourData.tagIdentifier, // Include the selected tag identifiers here
      },
      featureTour: updatedTourData.featureTour,
      specialTour: updatedTourData.specialTour,
      splTourToBMM: updatedTourData.splTourToBMM,
      placeId: updatedTourData.placeId,
    });
    console.log("payload", tourDataJson);
    const apiUrl = `${BaseAPI}/tour/vendor/${userData.userId}/create`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: tourDataJson,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data=========>", data);

        if (data.statusCode == 200) {
          setShowForm(false);
          resetForm();

          toast.success("Tour created successfully!");
        } else {
          toast.error(data.message || "Failed to create tour.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while creating the tour.");
        console.error("Error:", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const getCancellationPolicy = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/cancellationPolicy`);
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      setCancellationPolicy(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const getQuestion = async (name) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/questions/personalized-category/${name}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      setQuestionsData(data.data.question);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleFeatured = async () => {
    if (selectedFile.length > 0 && displayedFile.length === 0) {
      toast.warn("Please add the selected file before uploading.");
      return;
    }

    if (Array.isArray(displayedFile) && displayedFile.length > 0) {
      const formData = new FormData();
      formData.append("imageType", "featured");
      displayedFile.forEach((file) => {
        formData.append("file", file);
      });
      try {
        const response = await fetch(
          `${BaseAPI}/tour/${selectedTourId}/uploadFile`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("uploadfile", response);
        toast.success("File Uploaded added successfully");
      } catch (error) {
        console.error("Error submitting category:", error);
      }
    }
  };

  const handleSnapShot = async () => {
    if (
      selectedSnapshotsFile.length > 0 &&
      displayedSnapshotFiles.length === 0
    ) {
      toast.warn("Please add the selected files before uploading.");
      return;
    }

    if (
      Array.isArray(displayedSnapshotFiles) &&
      displayedSnapshotFiles.length > 0
    ) {
      const formData = new FormData();
      formData.append("imageType", "snapshot");
      displayedSnapshotFiles.forEach((file) => {
        formData.append("files", file);
      });
      try {
        const response = await fetch(
          `${BaseAPI}/tour/${selectedTourId}/uploadSnaphots`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          console.log("uploadSnapShot", response);

          throw new Error("Network response was not ok");
        }
        toast.success("File Uploaded added successfully");
        console.log("Category submitted successfully");
      } catch (error) {
        console.error("Error submitting category:", error);
      }
    }
  };

  const handleUploadImageClose = () => {
    setUploadImge(false);
    setDisplayedFile([]);
    setDisplayedSnapshotFiles([]);
  };

  const getPersonlizedSubCategory = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/personalized-category`);
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      setPersonlizedSubCategory(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const gettagIdentifiersByQuestionId = async (questionId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/tagIdentifiers/personalizedCategory/${questionId}`
      );
      if (!response.ok) {
        throw new Error("Error fetching all tours: ${response.statusText}");
      }
      const data = await response.json();
      setTagIdentifiers(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleBackButton = () => {
    setShowForm(false);
    setShowButton(true);
    setShowTable(true);
  };

  const handleRemoveFile = (index) => {
    const filteredFiles = displayedFile.filter((_, i) => i !== index);
    setDisplayedFile(filteredFiles);
    setSelectedFile(filteredFiles);
    if (filteredFiles.length === 0) {
      setIsInputDisabled(false);
      fileInputRefFeatured.current.value = "";
    }
  };

  const handlePlusCircleClickSnapshot = () => {
    if (selectedSnapshotsFile.length > 0) {
      setDisplayedSnapshotFiles([
        ...displayedSnapshotFiles,
        ...selectedSnapshotsFile,
      ]);
      SetselectedSnapshotsFile([]);
      fileInputRefSnapshots.current.value = "";
    }
  };

  const handleSnapshotRemoveFile = (index) => {
    const filteredFiles = displayedSnapshotFiles.filter((_, i) => i !== index);
    setDisplayedSnapshotFiles(filteredFiles);
    if (filteredFiles.length === 0) {
      fileInputRefSnapshots.current.value = "";
    }
  };

  const handleFeaturedImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFeaturedImage(file);
      setSelectedFile([file]);
    }
  };

  const handleSnapshotImagesChange = (event) => {
    if (event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      setSnapshotImages([...snapshotImages, ...filesArray]);
      SetselectedSnapshotsFile(filesArray);
    }
  };

  const handlePlusCircleClick = () => {
    if (selectedFile) {
      setDisplayedFile((prevFiles) => [...prevFiles, ...selectedFile]);
      setSelectedFile([]);
      fileInputRefFeatured.current.value = "";
      setIsInputDisabled(true);
    }
  };

  const handlePopupClose = () => {
    setshowDeletePopup(false);
  };

  const getAllCountry = async () => {
    try {
      const response = await fetch(`${BaseUrl}/rest/auth/all/countries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching all countries: ${response.statusText}`);
      }
      const data = await response.json();
      setAllCurrency(data.data);
    } catch (error) {
      console.error("Error fetching all countries:", error);
    }
  };

  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: "14px",
      height: "41px",
      borderColor: "#E3E8EE",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: "#E3E8EE",
      },
      outline: "none",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      width: "100%",
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "200px",
      overflowY: "auto",
      paddingBottom: 0,
    }),
    option: (base, state) => ({
      ...base,
    }),
    noOptionsMessage: (base) => ({
      ...base,
      display: "none",
    }),
  };

  const handleRemoveTagIdentifier = (index) => {
    const updatedTagIdentifiers = selectedTagIdentifiers.filter(
      (_, i) => i !== index
    );
    setSelectedTagIdentifiers(updatedTagIdentifiers);
  };

  const handleAddressChange = (value) => {
    setAddress(value);

    if (value === "") {
      setTourData((prevData) => ({
        ...prevData,
        latitude: "",
        longitude: "",
      }));
    }
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleAddQuestion = () => {
    const questionId = tourData.question;
    if (questionId && !selectedQuestions.includes(questionId)) {
      const questionText = questionsData.find(
        (q) => q.questionId === questionId
      )?.question;
      setSelectedQuestions([...selectedQuestions, questionText]);
    }
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = selectedQuestions.filter((_, i) => i !== index);
    setSelectedQuestions(updatedQuestions);
  };

  const handleAddQuestionAndTag = () => {
    const questionId = tourData.question;
    const tagIdentifiers = tourData.tagIdentifier;
  
    if (questionId && tagIdentifiers.length > 0) {
      const questionText = questionsData.find(
        (q) => q.questionId === questionId
      )?.question;
  
      setSelectedQuestions((prevQuestions) => {
        const existingQuestionIndex = prevQuestions.findIndex(
          (item) => item.question === questionText
        );
  
        if (existingQuestionIndex > -1) {
          // If question already exists, add new tags to it
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[existingQuestionIndex].tags = [
            ...new Set([
              ...updatedQuestions[existingQuestionIndex].tags,
              ...tagIdentifiers,
            ]),
          ];
          return updatedQuestions;
        } else {
          // Add new question with its tags
          return [
            ...prevQuestions,
            {
              question: questionText,
              tags: tagIdentifiers,
            },
          ];
        }
      });
  
      // Add tag identifiers to selectedTagIdentifiers
      setSelectedTagIdentifiers((prev) => [...prev, ...tagIdentifiers]);
  
      // Reset the inputs
      setTourData({
        ...tourData,
        question: "",
        tagIdentifier: [],
      });
    } else {
      toast.warn("Please select both a question and at least one tag identifier.");
    }
  };


  const handleRemoveQuestionAndTag = (questionIndex, tagIndex) => {
    setSelectedQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      const updatedTags = [...updatedQuestions[questionIndex].tags];
  
      updatedTags.splice(tagIndex, 1);
  
      if (updatedTags.length > 0) {
        updatedQuestions[questionIndex].tags = updatedTags;
      } else {
        updatedQuestions.splice(questionIndex, 1);
      }
  
      return updatedQuestions;
    });
  };
  
  const handleEditTour = (tour) => {
    setSelectedTourForEdit(tour);
    setcancellationPolicyId(tour.cancellationPolicyId);
    setShowEditPopup(true);
  };

  return (
    <>
      <div>
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-md-flex justify-content-end align-items-end mb-2">
                <nav aria-label="breadcrumb" className="d-inline-block">
                  <div className="float-end me-4 mt-4">
                  {!showEditPopup1 && (
                      <Button
                        variant="primary"
                        onClick={() => setShowForm(true)}
                      >
                        Create Tour
                      </Button>
                    )}
                  </div>
                </nav>
              </div>

              {!showForm ? (
                <TourDetails handleEditTour={handleEditTour} setShowEditPopup1={setShowEditPopup1} />
              ) : (
                <Container className="mt-3 ms-1">
                  <Row className="justify-content-center">
                    <Col lg={11} md={10}>
                      <h3 className="text-center mb-4">Create Tour</h3>
                      <Card>
                        <Card.Body>
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Title<span style={{ color: "red" }}> *</span>
                              </Form.Label>{" "}
                              <Form.Control
                                type="text"
                                placeholder="Enter tour name"
                                name="title"
                                value={tourData.title}
                                onChange={handleInputChange}
                                isInvalid={!!errors.title}
                              />
                              {errors.title && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.title}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                Short Description
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <ReactQuill
                                as="textarea"
                                rows={3}
                                className="h-50"
                                name="shortDescription"
                                required
                                value={he.decode(tourData.shortDescription)}
                                onChange={(value) =>
                                  handleInputTextChange(
                                    value,
                                    "shortDescription"
                                  )
                                }
                                isInvalid={!!errors.shortDescription}
                              />
                              {errors.shortDescription && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.shortDescription}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                Description
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <ReactQuill
                                as="textarea"
                                rows={3}
                                name="description"
                                value={he.decode(tourData.description)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "description")
                                }
                                isInvalid={!!errors.description}
                              />
                              {errors.description && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.description}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>
                                Highlights
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <ReactQuill
                                name="highlights"
                                theme="snow"
                                value={he.decode(tourData.highlights)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "highlights")
                                }
                                isInvalid={!!errors.highlights}
                              />
                              {errors.highlights && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.highlights}
                                </div>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>What's Included</Form.Label>
                              <ReactQuill
                                as="textarea"
                                type="textarea"
                                name="included"
                                value={he.decode(tourData.included)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "included")
                                }
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>What's Not Included</Form.Label>
                              <ReactQuill
                                type="textarea"
                                as="textarea"
                                name="notIncluded"
                                value={he.decode(tourData.notIncluded)}
                                onChange={(value) =>
                                  handleInputTextChange(value, "notIncluded")
                                }
                              />
                            </Form.Group>

                            <Form.Group className="mt-2 position-relative">
                              <Form.Label>
                                Search Location{" "}
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              {isGoogleMapsLoaded ? (
                                <PlacesAutocomplete
                                  value={address}
                                  onChange={handleAddressChange}
                                  onSelect={handleSelect}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div>
                                      <Form.Control
                                        {...getInputProps({
                                          placeholder: "Search Places ...",
                                          className: "location-search-input",
                                        })}
                                      />

                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}

                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#e0e0e0",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };

                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                              key={suggestion.placeId}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                              ) : (
                                <div>Loading Google Maps...</div>
                              )}
                              {errors.address && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {errors.address}
                                </div>
                              )}
                            </Form.Group>

                            <Row className="mb-3 mt-4">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>Latitude</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Latitude"
                                    name="latitude"
                                    value={tourData.latitude}
                                    onChange={handleInputChange}
                                  />
                                </Form.Group>
                              </Col>

                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>Longitude</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Longitude"
                                    name="longitude"
                                    value={tourData.longitude}
                                    onChange={handleInputChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group className="mb-3">
                              <Form.Label>Additional Information</Form.Label>
                              <ReactQuill
                                as="textarea"
                                rows={2}
                                name="additionalInformation"
                                value={he.decode(
                                  tourData.additionalInformation
                                )}
                                onChange={(value) =>
                                  handleInputTextChange(
                                    value,
                                    "additionalInformation"
                                  )
                                }
                              />
                            </Form.Group>

                            <Row className="mb-3">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Max Participants{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter tour maxParticipants"
                                    name="maxParticipants"
                                    value={tourData.maxParticipants}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.maxParticipants}
                                  />
                                  {errors.maxParticipants && (
                                    <div
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.maxParticipants}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>
                                    Tour Max Capacity{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="capacity"
                                    placeholder="Enter tour max capacity"
                                    value={tourData.capacity}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.capacity}
                                  />
                                  {errors.capacity && (
                                    <div
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.capacity}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Cancellation{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="cancelation"
                                    placeholder="Enter tour cancellation Days"
                                    value={tourData.cancelation}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.cancelation}
                                  />
                                  {errors.cancelation && (
                                    <div
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.cancelation}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col md={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Cancellation Policy{" "}
                                    <span style={{ color: "red" }}> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    onChange={handleCancellationChange}
                                    name="category"
                                    isInvalid={!!errors.cancellationPolicyId}
                                  >
                                    <option value="">
                                      Select cancellation policy
                                    </option>
                                    {cancellationPolicy &&
                                      cancellationPolicy.map((policy) => (
                                        <option
                                          key={policy.policyName}
                                          value={policy.cancellationPolicyId}
                                        >
                                          {policy.policyName}
                                        </option>
                                      ))}
                                  </Form.Control>
                                  {errors.cancellationPolicyId && (
                                    <div
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {errors.cancellationPolicyId}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label>Feature Tour</Form.Label>
                                  <div>
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="Yes"
                                      name="featureTour"
                                      value={1}
                                      checked={tourData.featureTour === 1}
                                      onChange={handleInputChange}
                                    />
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="No"
                                      name="featureTour"
                                      value={0}
                                      checked={tourData.featureTour === 0}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label>Special Tour</Form.Label>
                                  <div>
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="Yes"
                                      name="specialTour"
                                      value={1}
                                      checked={tourData.specialTour === 1}
                                      onChange={handleInputChange}
                                    />
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="No"
                                      name="specialTour"
                                      value={0}
                                      checked={tourData.specialTour === 0}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label>Special BMM Tour</Form.Label>
                                  <div>
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="Yes"
                                      name="splTourToBMM"
                                      value={1}
                                      checked={tourData.splTourToBMM === 1}
                                      onChange={handleInputChange}
                                    />
                                    <Form.Check
                                      inline
                                      type="radio"
                                      label="No"
                                      name="splTourToBMM"
                                      value={0}
                                      checked={tourData.splTourToBMM === 0}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label>
                                    Select Personalized Subcategories
                                  </Form.Label>
                                  <Form.Select
                                    name="personlizedSubCategory"
                                    value={tourData.personlizedSubCategory}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">
                                      Select personalized subcategories
                                    </option>
                                    {Array.isArray(personlizedSubCategory) &&
                                      personlizedSubCategory.map((subCat) => (
                                        <option
                                          key={subCat.name}
                                          value={subCat.name}
                                        >
                                          {subCat.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {tourData.personlizedSubCategory && (
                                    <div className="mt-2">
                                      <div className="d-flex align-items-center">
                                        <div className="me-2">
                                          {tourData.personlizedSubCategory}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col md={8}>
                                <Form.Group>
                                  <Form.Label>
                                    Select Question and Tag Identifier
                                  </Form.Label>
                                  <div className="d-flex align-items-center">
                                    <Form.Select
                                      name="question"
                                      value={tourData.question}
                                      onChange={handleInputChange}
                                      className="me-2"
                                      style={{ flex: "1" }}
                                    >
                                      <option value="">Select Question</option>
                                      {Array.isArray(questionsData) &&
                                        questionsData.map((question) => (
                                          <option
                                            key={question.questionId}
                                            value={question.questionId}
                                          >
                                            {question.question}
                                          </option>
                                        ))}
                                    </Form.Select>

                                    <CreatableSelect
                                      name="tagIdentifier"
                                      placeholder="Select or create Tag Identifier"
                                      value={
                                        tourData.tagIdentifier
                                          ? tourData.tagIdentifier.map(
                                              (tag) => ({
                                                value: tag,
                                                label: tag,
                                              })
                                            )
                                          : []
                                      }
                                      onChange={(selectedOptions) =>
                                        {console.log("selectedOptions===>",selectedOptions);
                                        setTourData({
                                          ...tourData,
                                          tagIdentifier: selectedOptions
                                            ? selectedOptions.map(
                                                (option) => option.value
                                              )
                                            : [],
                                        })
                                      }
                                      }
                                      options={tagIdentifiers.map((tag) => ({
                                        value: tag.tagIdentifier,
                                        label: tag.tagIdentifier,
                                      }))}
                                      isMulti
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          minHeight: "40px",
                                          height: "auto",
                                          borderRadius: "0.375rem",
                                          borderColor: state.isFocused
                                            ? "#5852F7"
                                            : "#ccc",
                                          boxShadow: "none",
                                          "&:hover": {
                                            borderColor: state.isFocused
                                              ? "#5852F7"
                                              : "#A0A3BD",
                                          },
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          height: "auto",
                                          padding: "0 6px",
                                          flexWrap: "wrap",
                                        }),
                                        multiValue: (provided) => ({
                                          ...provided,
                                          backgroundColor: "#F6F7FE",
                                          borderRadius: "15px",
                                          padding: "1px 5px",
                                          margin: "4px",
                                          maxWidth: "none",
                                        }),
                                        multiValueLabel: (provided) => ({
                                          ...provided,
                                          color: "#000",
                                          whiteSpace: "normal",
                                        }),
                                        multiValueRemove: (provided) => ({
                                          ...provided,
                                          color: "#000",
                                          marginTop: "3px",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                            color: "#000",
                                          },
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isFocused
                                            ? "#F6F7FE"
                                            : state.isSelected
                                            ? "#F6F7FE"
                                            : "#fff",
                                          color: "#000",
                                          "&:hover": {
                                            backgroundColor: "#F6F7FE",
                                          },
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          backgroundColor: "#F6F7FE",
                                        }),
                                        menuList: (provided) => ({
                                          ...provided,
                                          maxHeight: "100px",
                                          overflowY: "auto",
                                        }),
                                      }}
                                    />

                                    <PlusCircle
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                      }}
                                      onClick={handleAddQuestionAndTag}
                                    />
                                  </div>

                                  {/* Display added questions */}
                                  {selectedQuestions.length > 0 && (
  <div className="mt-2">
    <ul className="list-unstyled">
      {selectedQuestions.map((item, index) => (
        <li key={index} className="d-flex flex-column">
          <div className="fw-bold">{item.question}</div>
          <div className="d-flex flex-wrap">
            {item.tags.map((tag, tagIndex) => (
              <div key={tagIndex} className="me-2 d-flex align-items-center">
                <span className="me-1">{tag}</span>
                <DashCircle
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleRemoveQuestionAndTag(index, tagIndex)
                  }
                />
              </div>
            ))}
          </div>
        </li>
      ))}
    </ul>
  </div>
)}
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <div className="row">
                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Select Theme{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Form.Control
                                      as="select"
                                      name="themeId"
                                      value={tourData?.themeId}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">Select a Theme</option>
                                      {themes &&
                                        Array.isArray(themes) &&
                                        themes.map((theme) => (
                                          <option
                                            key={theme?.themeId || theme?.id}
                                            value={theme?.themeId || theme?.id}
                                          >
                                            {theme?.themeName || theme?.name}
                                          </option>
                                        ))}
                                    </Form.Control>
                                    <div className="selection-names-container">
                                      {renderThemeSelections()}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Category{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Form.Control
                                      as="select"
                                      name="categoryId"
                                      value={tourData.categoryId}
                                      onChange={handleInputChange}
                                    >
                                      <option value="">
                                        Select a Category
                                      </option>
                                      {categories &&
                                        Array.isArray(categories) &&
                                        categories.map((category) => (
                                          <option
                                            key={category.categoryId}
                                            value={category.categoryId}
                                          >
                                            {category.categoryName}
                                          </option>
                                        ))}
                                    </Form.Control>
                                    <div className="selection-names-container">
                                      {renderCategorySelections()}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="select-wrapper">
                                    <Form.Label className="form-label">
                                      Subcategory{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <div className="d-flex align-items-center">
                                      <Form.Control
                                        as="select"
                                        name="subCategoryId"
                                        value={tourData.subCategoryId}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">
                                          Select a Subcategory
                                        </option>
                                        {subCategories &&
                                          Array.isArray(subCategories) &&
                                          subCategories.map((subCategory) => (
                                            <option
                                              key={subCategory.subCategoryId}
                                              value={subCategory.subCategoryId}
                                            >
                                              {subCategory.subCategoryName}
                                            </option>
                                          ))}
                                      </Form.Control>
                                      <PlusCircle
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "20px",
                                        }}
                                        onClick={
                                          handleAddThemeCategorySubcategory
                                        }
                                        className="mt-2 ms-2 "
                                      />
                                    </div>
                                    <div className="selection-names-container">
                                      {renderSubCategorySelections()}
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </Row>

                            <div className="d-flex justify-content-center mt-4">
                              <Button
                                type="submit"
                                variant="primary"
                                className="me-2"
                                disabled={isloading}
                                onClick={handleCreateTour}
                              >
                                {isloading ? "Creating..." : "Create"}
                              </Button>

                              <Button
                                variant="secondary"
                                onClick={handleBackButton}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              )}
              <LocationForm
                show={showLocationModal}
                handleClose={handleCloseLocationModal}
                tourId={locationFormTourId}
              />
              <TourView
                show={showViewPopup}
                handleClose={() => setShowViewPopup(false)}
                selectedTourForView={selectedTourForView}
              />
              {showEditPopup && (
                <TourEdit
                  show={showEditPopup}
                  handleClose={() => setShowEditPopup(false)}
                  tour={selectedTourForEdit}
                  cancellationPolicyId={cancellationPolicyId}
                />
              )}
            </div>

            <Modal show={showDeletePopup} onHide={handlePopupClose}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="delete center">
                  Are you want to delete tour?
                </div>
              </Modal.Body>
              <Button
                variant="btn btn-secondary w-25 ms-3 close"
                onClick={handlePopupClose}
              >
                Close
              </Button>
              &nbsp;
            </Modal>

            <Modal show={uploadImage} onHide={handleUploadImageClose}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Row className="mb-5">
                    <Col md={6} className="mt-2">
                      <Form.Label className="form-label">
                        Featured Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleFeaturedImageChange}
                        ref={fileInputRefFeatured}
                        disabled={isInputDisabled}
                      />
                      <PlusCircle
                        style={{ marginLeft: "280px", marginTop: "-64px" }}
                        onClick={handlePlusCircleClick}
                      />
                    </Col>

                    <div>
                      {displayedFile?.length > 0 ? (
                        displayedFile?.map((file, index) => (
                          <div key={index} className="text-center">
                            {file.name}
                            <DashCircle
                              className="ms-3"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => handleRemoveFile(index)}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="text-center text-muted"></div>
                      )}
                    </div>
                    <Button
                      className="w-25 h-25 mt-3 ms-3"
                      onClick={() => handleFeatured()}
                    >
                      Submit
                    </Button>
                  </Row>

                  <Row className="mb-5">
                    <Col md={6} className="mt-1">
                      <Form.Label className="form-label">
                        Snapshot Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        multiple
                        ref={fileInputRefSnapshots}
                        onChange={handleSnapshotImagesChange}
                        id="SNAPSHOT"
                      />
                      <PlusCircle
                        style={{ marginLeft: "280px", marginTop: "-64px" }}
                        onClick={handlePlusCircleClickSnapshot}
                      />
                    </Col>

                    <div>
                      {displayedSnapshotFiles.length > 0 ? (
                        displayedSnapshotFiles.map((file, index) => (
                          <div key={index} className="text-center">
                            {file.name}
                            <DashCircle
                              className="ms-3"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => handleSnapshotRemoveFile(index)}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="text-center text-muted"></div>
                      )}
                    </div>
                    <Button
                      className="w-25 h-25 align-center ms-3"
                      onClick={() => handleSnapShot()}
                    >
                      Submit
                    </Button>
                  </Row>
                </div>
                <div className="d-flex justify-content-center">
                  <Button variant="secondary" onClick={handleUploadImageClose}>
                    Close
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}

export default TourCreate;
