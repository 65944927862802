import {
  Form,
  Button,
  Table,
  Container,
  Col,
  Row,
  Modal,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import {
  PencilFill,
  Trash3,
  PlusCircle,
  PersonCircle,
  EyeFill,
} from "react-bootstrap-icons";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../common/navbar";
import AdminSidebar from "../adminSidebar";

function ThemeSettings() {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const baseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

  const [allCompany, setallCompany] = useState([]);
  const [toursByCompany, settoursByCompany] = useState([]);
  const [selectedVendorID, setSelectedVendorID] = useState("");
  const [openSubCategoryVeiwPopup, setopenSubCategoryVeiw] = useState(false);
  const [themes, setThemes] = useState([]);
  const [selectedThemeId, setselectedThemeId] = useState([]);
  const [showTables, setshowTables] = useState(false);
  const [showCategoryButton, setshowCategoryButton] = useState(false);
  const [ShowSubcategoryButton, setShowSubcategoryButton] = useState(false);
  const [selectedSubCategory, setselectedSubCategory] = useState(false);
  const [openCategoryPopup, setopenCategoryPopup] = useState(false);
  const [openSubCategoryPopup, setopenSubCategoryPopup] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setselectedCategoryId] = useState();
  const [subCategories, setSubCategories] = useState([]);
    
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);
  
  const [formCategoryData, setCategoryFormData] = useState({
    categoryName: "",
    Description: "",
    images: [],
  });

  const [formSubCategoryData, setSubCategoryFormData] = useState({
    subCategoryName: "",
    Description: "",
    images: [],
  });



  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  useEffect(() => {
    fetchTheme();
    if (selectedThemeId) {
      fetchCategoriesByTheme(selectedThemeId);
    }
  }, [selectedThemeId]);

  const fetchTheme = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/themes`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setThemes(data.data);
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const handleInputChange = (e) => {
    setselectedThemeId(e.target.value);
    setshowTables(false);
    setshowCategoryButton(true);
    setShowSubcategoryButton(false);
    if (categories) {
      fetchCategoriesByTheme(selectedThemeId);
    } else {
      setCategories([]);
    }
  };

  const handleCategoryInputChange = (e) => {
    const selectedCategoryId = e.target.value;
    setselectedCategoryId(selectedCategoryId);
    fetchSubCategories(selectedCategoryId);
    setShowSubcategoryButton(true);
    if (categories) {
      setshowTables(true);
    }
  };

  const fetchCategoriesByTheme = async (themeId) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/category/theme/${themeId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data.data);
      console.log("data.data", data.data);
    } catch (error) {
      console.error("Error fetching categories by theme:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/subcategory/category/${categoryId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleViewClick = (subCategories) => {
    setselectedSubCategory(subCategories);
    setopenSubCategoryVeiw(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategoryFormData({ ...formCategoryData, [name]: value });
  };

  const handleChangeSubCategory = (event) => {
    const { name, value } = event.target;
    setSubCategoryFormData({ ...formSubCategoryData, [name]: value });
  };

  const handleImageCategory = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setCategoryFormData((prevData) => {
      const updatedFiles = [...prevData.images, ...selectedFiles];
      return {
        ...prevData,
        images: updatedFiles,
      };
    });
  };

  const handleImageSubCategory = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setSubCategoryFormData((prevData) => {
      const updatedFiles = [...prevData.images, ...selectedFiles];
      return {
        ...prevData,
        images: updatedFiles,
      };
    });
  };

  const handleCategory = () => {
    setopenCategoryPopup(true);
  };

  const openSubCategory = () => {
    setopenSubCategoryPopup(true);
  };

  const handleCategoryPopupClose = () => {
    setopenCategoryPopup(false);
  };

  const handleSubCategoryPopupClose = () => {
    setopenSubCategoryPopup(false);
  };

  const handleViewPopupClose = () => {
    setopenSubCategoryVeiw(false);
  };

  const handleCategorySubmit = async (event) => {
    event.preventDefault();
    const categoryData = {
      categoryName: formCategoryData.categoryName,
      categoryDesc: formCategoryData.Description,
    };
    const formData = new FormData();
    formData.append("categoryData", JSON.stringify(categoryData));
    formCategoryData.images.forEach((file) => {
      formData.append("files", file);
    });
    try {
      const response = await fetch(
        `${BaseAPI}/tour/upload/categoryData/theme/${selectedThemeId}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      toast.success("Category added successfully");
      setopenCategoryPopup(false);
      fetchCategoriesByTheme(selectedThemeId);
    } catch (error) {
      console.error("Error submitting category:", error);
    }
  };

  const handleSubCategorySubmit = async (event) => {
    event.preventDefault();
    const subCategoryData = {
      subCategoryName: formSubCategoryData.subCategoryName,
      Desc: formSubCategoryData.Description,
    };
    const formData = new FormData();
    formData.append("categoryData", JSON.stringify(subCategoryData));
    formSubCategoryData.images.forEach((file) => {
      formData.append("files", file);
    });
    try {
      const response = await fetch(
        `${BaseAPI}/tour/upload/subCategoryData/category/${selectedCategoryId}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      toast.success("SubCategory added successfully");
      setopenSubCategoryPopup(false);
      fetchSubCategories(selectedCategoryId);
    } catch (error) {
      console.error("Error submitting category:", error);
    }
  };

  return (
    <>
      <div>
        <div>
          <AdminSidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <div className="container-fluid mt-5">
                  <Row className="align-items-center">
                    <Col xs={12} md={3}>
                      <Form.Group controlId="formTheme" className="mb-3">
                        <Form.Label className="ms-2">Select Theme:</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handleInputChange}
                          name="theme"
                          className="w-100 border-1 border-dark"
                        >
                          <option value="">Select a theme</option>
                          {themes &&
                            Array.isArray(themes) &&
                            themes.map((theme) => (
                              <option key={theme.themeId} value={theme.themeId}>
                                {theme.themeName}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={3}>
                      <Form.Group controlId="formCategory" className="mb-3">
                        <Form.Label className="ms-2">
                          Select Category:
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={handleCategoryInputChange}
                          name="category"
                          className="w-100 border-1 border-dark"
                        >
                          <option value="">Select a category</option>
                          {categories &&
                            Array.isArray(categories) &&
                            categories.map((category) => (
                              <option
                                key={category.categoryId}
                                value={category.categoryId}
                              >
                                {category.categoryName}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col
                      xs={12}
                      md={3}
                      className="d-flex justify-content-center"
                    >
                      {showCategoryButton && (
                        <Button
                          className="mt-4"
                          type="submit"
                          variant="primary"
                          onClick={handleCategory}
                        >
                          Add Categories
                        </Button>
                      )}
                    </Col>

                    <Col
                      xs={12}
                      md={3}
                      className="d-flex justify-content-center"
                    >
                      {ShowSubcategoryButton && (
                        <Button
                          className="mt-4"
                          type="submit"
                          variant="primary"
                          onClick={openSubCategory}
                        >
                          Add Sub Categories
                        </Button>
                      )}
                    </Col>
                  </Row>

                  {showTables && (
                    <div className="row">
                      <div
                        className="col-8 mt-5 ms-2 "
                        style={{ width: "100%" }}
                      >
                        <div className="table-responsive shadow rounded me-3">
                          <table className="table table-center bg-white mb-0">
                            <thead>
                              <tr className="tour-td text-center">
                                <th className="border-bottom p-3">Sr.No.</th>
                                <th className="border-bottom p-3">
                                  Sub Category
                                </th>
                                <th className="border-bottom p-3">
                                  Description
                                </th>
                                <th className="border-bottom p-3">Image</th>
                                {/* <th className="border-bottom p-3">Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {subCategories &&
                              Array.isArray(subCategories) &&
                              subCategories.length > 0 ? (
                                subCategories.map((subCategory, index) => (
                                  <tr key={index}>
                                    <td className="tour-td text-center p-3">
                                      {index + 1}
                                    </td>
                                    <td className="tour-td text-center p-3">
                                      {subCategory.subCategoryName}
                                    </td>
                                    <td className="tour-td text-center p-3">
                                      {subCategory.desc}
                                    </td>
                                    <td className="tour-td text-center p-3">
                                      <img
                                        src={subCategory.imageUrl}
                                        width="20px"
                                        height="20px"
                                        alt="Subcategory Image"
                                      />
                                    </td>
                                    {/* <td className="tour-td text-center p-3">
                                      <EyeFill
                                        onClick={() =>
                                          handleViewClick(subCategory)
                                        }
                                      />
                                      <span className="ms-3">
                                        <PencilFill />
                                      </span>
                                      <span className="ms-3">
                                        <Trash3 />
                                      </span>
                                    </td> */}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center p-3">
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default ThemeSettings;
