import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from './auth.service';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const token = AuthService.getAuthToken();

  return token ? <Element {...rest} /> : <Navigate to="/" />;
};

export default PrivateRoute;
