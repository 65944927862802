import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Button,
  Modal,
  Form,
  Table,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import {
  GeoAlt,
  BookmarkFill,
  Bookmark,
  ArrowRight,
  ArrowLeft,
  BoxArrowInUp,
  PlusCircle,
  DashCircle,
  Trash3,
  ThreeDotsVertical,
  PencilFill,
  TrashFill,
} from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../tour/tours.css";
import { useSelector } from "react-redux";
import blankImg from "../../assets/images/download (2).png";
import uploadImageIcon from "../../assets/images/downloadimageIcon.png";
import { useNavigate } from "react-router-dom";
import cc from "currency-codes";
import Select from "react-select";
import CurrencyFlag from "react-currency-flags";
import TourEdit from "./TourEdit";
import axios from "axios";

const TourDetails = ({ setShowEditPopup1 }) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const [displayedTours, setDisplayedTours] = useState([]);

  const [wishlist, setWishlist] = useState(new Set());
  const [hasMoreTours, setHasMoreTours] = useState(false);

  const [allTours, setAllTours] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [imageType, setImageType] = useState({});
  const [selectedTourId, setSelectedTourId] = useState("");
  const [allToursByVendorId, setAllToursByVendorId] = useState([]);
  const [createdItineraries, setCreatedItineraries] = useState([]);
  const [allItineraries, setAllItineraries] = useState([]);

  const [selectedFile, setSelectedFile] = useState([]);
  const [displayedFile, setDisplayedFile] = useState([]);
  const [selectedSnapshotsFile, SetselectedSnapshotsFile] = useState([]);
  const [displayedSnapshotFiles, setDisplayedSnapshotFiles] = useState([]);
  const fileInputRefFeatured = useRef(null);
  const fileInputRefSnapshots = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [snapshotImages, setSnapshotImages] = useState([]);
  const [themes, setThemes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { userData, token } = useSelector((state) => state);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [itineraries, setItineraries] = useState([
    { dayNumber: 1, description: "", startTime: "", endTime: "" },
  ]);
  const [visibleToursCount, setVisibleToursCount] = useState(8);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const navigate = useNavigate();
  const [currencyList, setCurrencyList] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [tourToDelete, setTourToDelete] = useState(null);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [loadingSnapshot, setLoadingSnapshot] = useState(false);

  const [formData, setFormData] = useState({
    scheduleTitle: "",
    startDate: "",
    duration: "",
    durationTime: "",
    price: "",
    currency: "",
    discount: "",
  });

  useEffect(() => {
    const currencies = cc.codes().map((code) => {
      const currency = cc.code(code);
      return {
        name: currency.name,
        code: currency.code,
      };
    });
    setCurrencyList(currencies);
  }, []);

  useEffect(() => {
    const options = cc.codes().map((code) => ({
      value: code,
      label: `${code}`,
    }));
    setCurrencyOptions(options);
  }, []);

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      currency: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    fetchAllToursByVendorId();
    fetchAllThemes();
  }, []);

  const fetchAllToursByVendorId = async () => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/enterprise/${userData.data.enterpriseCompanyId}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();

      const activeTours = data.data.filter((tour) => tour.status === 1);

      setAllTours(activeTours);
      setDisplayedTours(activeTours.slice(0, visibleToursCount));
      setHasMoreTours(activeTours.length > visibleToursCount);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleShowMoreTours = () => {
    const nextToursCount = visibleToursCount + 8;
    setDisplayedTours(allTours.slice(0, nextToursCount));
    setVisibleToursCount(nextToursCount);
    setHasMoreTours(allTours.length > nextToursCount);
  };

  const handleShowLessTours = () => {
    setDisplayedTours(allTours.slice(0, 8));
    setVisibleToursCount(8);
    setHasMoreTours(allTours.length > 8);
  };

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    const url = `https://eurekatrip.com/#/tour/${tourId}`;
    window.open(url, "_blank");
  };

  const addToWishlist = (tourId) => {
    setWishlist(new Set(wishlist).add(tourId));
    toast.success("Added to wishlist");
  };

  const removeFromWishlist = (tourId) => {
    const newWishlist = new Set(wishlist);
    newWishlist.delete(tourId);
    setWishlist(new Set(newWishlist));
    toast.info("Removed from wishlist");
  };

  const uploadImages = (tourData) => {
    setSelectedTourId(tourData.tourId);
    setUploadImage(true);
  };

  const handleFeatured = async () => {
    if (selectedFile.length > 0 && displayedFile.length === 0) {
      toast.warn("Please add the selected file before uploading.");
      return;
    }

    setLoadingFeatured(true);

    if (Array.isArray(displayedFile) && displayedFile.length > 0) {
      const formData = new FormData();
      formData.append("imageType", "featured");
      displayedFile.forEach((file) => {
        formData.append("file", file);
      });
      try {
        const response = await fetch(
          `${BaseAPI}/tour/${selectedTourId}/uploadFile`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("uploadfile", response);
        toast.success("File Uploaded successfully");
        fetchAllToursByVendorId();
      } catch (error) {
        console.error("Error submitting category:", error);
      } finally {
        setLoadingFeatured(false);
      }
    }
  };

  const handleSnapShot = async () => {
    if (
      selectedSnapshotsFile.length > 0 &&
      displayedSnapshotFiles.length === 0
    ) {
      toast.warn("Please add the selected files before uploading.");
      return;
    }

    if (
      Array.isArray(displayedSnapshotFiles) &&
      displayedSnapshotFiles.length > 0
    ) {
      setLoadingSnapshot(true);
      const formData = new FormData();
      formData.append("imageType", "snapshot");
      displayedSnapshotFiles.forEach((file) => {
        formData.append("files", file);
      });
      try {
        console.log(`Selected Tour ID====>: ${selectedTourId}`);

        const response = await fetch(
          `${BaseAPI}/tour/${selectedTourId}/uploadSnaphots`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        toast.success("File Uploaded  successfully");
        fetchAllToursByVendorId();
        setUploadImage(false);

        console.log("Category submitted successfully");
      } catch (error) {
        console.error("Error submitting category:", error);
      } finally {
        setLoadingSnapshot(false);
      }
    }
  };

  const fetchAllThemes = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/themes`);
      if (!response.ok) {
        throw new Error("Failed to fetch themes");
      }
      const data = await response.json();
      setThemes(data.data);
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const getThemeName = (themeId) => {
    const theme = themes.find((t) => t.tourThemeId === themeId);
    return theme ? theme.themeName : "Theme Not Available";
  };

  const handleRemoveFile = (index) => {
    const filteredFiles = displayedFile.filter((_, i) => i !== index);
    setDisplayedFile(filteredFiles);
    setSelectedFile(filteredFiles);
    if (filteredFiles.length === 0) {
      setIsInputDisabled(false);
      fileInputRefFeatured.current.value = "";
    }
  };

  const handlePlusCircleClickSnapshot = () => {
    if (selectedSnapshotsFile.length > 0) {
      setDisplayedSnapshotFiles([
        ...displayedSnapshotFiles,
        ...selectedSnapshotsFile,
      ]);
      SetselectedSnapshotsFile([]);
      fileInputRefSnapshots.current.value = "";
    }
  };

  const handlePlusCircleClick = () => {
    if (selectedFile) {
      setDisplayedFile((prevFiles) => [...prevFiles, ...selectedFile]);
      setSelectedFile([]);
      fileInputRefFeatured.current.value = "";
      setIsInputDisabled(true);
    }
  };

  // const handleSnapshotImagesChange = (event) => {
  //   if (event.target.files.length > 0) {
  //     const filesArray = Array.from(event.target.files);
  //     setSnapshotImages([...snapshotImages, ...filesArray]);
  //     SetselectedSnapshotsFile(filesArray);
  //   }
  // };

  const handleFeaturedImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFeaturedImage(file);
      setDisplayedFile((prevFiles) => [...prevFiles, file]);
      setSelectedFile([file]);
    }
  };

  const handleSnapshotImagesChange = (event) => {
    if (event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      setSnapshotImages([...snapshotImages, ...filesArray]);
      setDisplayedSnapshotFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  const handleSnapshotRemoveFile = (index) => {
    const filteredFiles = displayedSnapshotFiles.filter((_, i) => i !== index);
    setDisplayedSnapshotFiles(filteredFiles);
    if (filteredFiles.length === 0) {
      fileInputRefSnapshots.current.value = "";
    }
  };

  // const handleFeaturedImageChange = (event) => {
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     setFeaturedImage(file);
  //     setSelectedFile([file]);
  //   }
  // };

  // const handleUploadImageClose = () => {
  //   setUploadImage(false);
  //   setDisplayedFile([]);
  //   setDisplayedSnapshotFiles([]);
  // };

  const handleUploadImageClose = () => {
    setUploadImage(false);
    setDisplayedFile([]);
    setDisplayedSnapshotFiles([]);
    setSelectedFile([]);
    SetselectedSnapshotsFile([]);
    fileInputRefFeatured.current.value = "";
    fileInputRefSnapshots.current.value = "";
  };

  const handleShowModal = (tour) => {
    setShowModal(true);
    setSelectedTourId(tour.tourId);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleAddRow = () => {
    setItineraries([
      ...itineraries,
      { dayNumber: "", description: "", startTime: "", endTime: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const newItineraries = itineraries.filter((_, i) => i !== index);
    setItineraries(newItineraries);
  };

  const handleInputChange = (index, field, value) => {
    const newItineraries = [...itineraries];
    newItineraries[index][field] = value;
    setItineraries(newItineraries);
  };

  const handleCreate = async () => {
    if (!selectedSchedule) {
      console.error("Selected Schedule is null or undefined");
      toast.error("Please select a schedule before creating an itinerary.");
      return;
    }

    const payload = itineraries.map(
      ({ dayNumber, description, startTime, endTime }) => ({
        dayNumber,
        description,
        startTime,
        endTime,
      })
    );

    try {
      console.log("Selected Schedule:", selectedSchedule);
      console.log("Selected Schedule ID:", selectedSchedule?.scheduleId);
      console.log("Payload:", JSON.stringify(payload));

      const response = await fetch(
        `${BaseAPI}/tour/create/itinerary/schedule/${selectedSchedule?.scheduleId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Error Response: ${errorText}`);
        throw new Error(`Error saving itinerary: ${errorText}`);
      }

      const data = await response.json();
      toast.success("Itinerary added successfully!");
      setCreatedItineraries([...createdItineraries, ...payload]);
      setShowModal(false);
    } catch (error) {
      console.error("Error creating itinerary:", error);
      toast.error("Failed to save itinerary.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Selected Tour ID==========>:", selectedTourId);

    try {
      const response = await fetch(
        `${BaseAPI}/tour/${selectedTourId}/schedule`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        toast.success("Schedule created successfully");
        navigate("/scheduled-tour");
        fetchAllToursByVendorId();
        setShowSubmitButton(true);
        setFormData({
          scheduleTitle: "",
          startDate: "",
          duration: "",
          durationTime: "",
          price: "",
          // currency:"",
          discount: "",
        });
        handleCloseModal();
      } else {
        const errorText = await response.text();
        console.error("Failed to create schedule:", errorText);
        toast.error("Failed to create schedule");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while creating the schedule");
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px",
      borderRadius: "2px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      width: "180px",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      padding: "8px 12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      overflow: "hidden",
      zIndex: 9999,
    }),
  };

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CurrencyFlag currency={value} size="lg" style={{ marginRight: 10 }} />
      {label}
    </div>
  );

  const handleCloseEditModalAndRefresh = () => {
    setShowEditModal(false);
    setSelectedTour(null);
    fetchAllToursByVendorId();
    
  };

  const handleThreeDotsClick = (tour) => {
    setSelectedTour(tour);
    setShowEditModal(true);
    setShowEditPopup1(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedTour(null);
  };

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const confirmDeleteTour = (tour) => {
    setTourToDelete(tour);
    setShowDeleteConfirmModal(true);
  };

  const handleCloseDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(false);
    setTourToDelete(null);
  };

  const handleDeleteTour = async () => {
    if (!tourToDelete) return;

    try {
      const response = await axios.post(
        `${BaseAPI}/tour/delete/tour/${tourToDelete.tourId}`
      );

      if (response.status === 200 && response.data.status === "Success") {
        toast.success("Tour Deleted Successfully");
        setAllTours((prevTours) =>
          prevTours.filter((tour) => tour.tourId !== tourToDelete.tourId)
        );
        setDisplayedTours((prevDisplayed) =>
          prevDisplayed.filter((tour) => tour.tourId !== tourToDelete.tourId)
        );
      } else {
        toast.error("Error deleting tour");
        console.error("Failed to delete tour:", response.data.statusMessage);
      }
    } catch (error) {
      console.error("Error deleting tour:", error);
      toast.error("Error deleting tour");
    } finally {
      setShowDeleteConfirmModal(false);
      setTourToDelete(null);
    }
  };

  // const handleDeleteTour = async (tourId) => {
  //   try {
  //     const response = await axios.post(
  //       `${BaseAPI}/tour/delete/tour/${tourId}`
  //     );

  //     if (response.status === 200 && response.data.status === "Success") {
  //       console.log("Tour Deleted Successfully:", response.data);
  //       toast.success("Tour Deleted Successfully");
  //       setAllTours((prevTours) =>
  //         prevTours.filter((tour) => tour.tourId !== tourId)
  //       );
  //       setDisplayedTours((prevDisplayed) =>
  //         prevDisplayed.filter((tour) => tour.tourId !== tourId)
  //       );
  //     } else {
  //       toast.error("Error deleting tour");

  //       console.error("Failed to delete tour:", response.data.statusMessage);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting tour:", error);
  //     toast.error("Error deleting tour");

  //   }
  // };

  return (
    <Container fluid className="custom-container h-auto mt-4 px-5 font-common">
      {!showEditModal && (
        <>
          <h1 className="text-start feature-heading">Tours</h1>
        </>
      )}{" "}
      {!showEditModal && (
        <>
          <Row>
            {displayedTours.map((tour, idx) => (
              <Col xs={12} sm={6} md={4} lg={3} key={idx}>
                <Card className="h-100" style={{ border: "none" }}>
                  <div className="carousel-hover">
                    <Carousel
                      className="carousel-overlay"
                      interval={null}
                      nextIcon={
                        <span
                          style={{
                            backgroundColor: "black",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                        >
                          <ArrowRight style={{ color: "white" }} />
                        </span>
                      }
                      prevIcon={
                        <span
                          style={{
                            backgroundColor: "black",
                            borderRadius: "50%",
                            padding: "5px",
                          }}
                        >
                          <ArrowLeft style={{ color: "white" }} />
                        </span>
                      }
                    >
                      {tour.tourAssets && tour.tourAssets.length > 0 ? (
                        tour.tourAssets.map((asset, assetIdx) => (
                          <Carousel.Item
                            key={assetIdx}
                            className="card-img-container"
                          >
                            <img
                              onClick={() => handleTourCardClick(tour)}
                              src={asset.assetUrl || blankImg}
                              alt={`Slide ${assetIdx}`}
                              onError={(e) => {
                                console.log(
                                  "Image failed to load. Setting to default image."
                                );
                                e.target.src = blankImg;
                              }}
                              className="img-fluid"
                              style={{ cursor: "pointer" }}
                            />
                          </Carousel.Item>
                        ))
                      ) : (
                        <Carousel.Item className="card-img-container">
                          <img
                            onClick={() => handleTourCardClick(tour)}
                            src={blankImg}
                            alt="Default image"
                            className="img-fluid"
                            style={{ cursor: "pointer" }}
                          />
                        </Carousel.Item>
                      )}
                    </Carousel>
                    <div className="overlay-text">
                      {getThemeName(tour.tourTheme[0]?.themeId)}
                    </div>

                    <div className="three-dots-container">
                      <Dropdown align="end" className="main-dropdown">
                        <Dropdown.Toggle
                          variant="link"
                          bsPrefix="p-0"
                          id="dropdown-basic"
                          style={{
                            cursor: "pointer",
                            color: "black",
                          }}
                        >
                          <ThreeDotsVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleThreeDotsClick(tour)}
                          >
                            <PencilFill className="me-2" /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => confirmDeleteTour(tour)}
                          >
                            <TrashFill className="me-2" /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-2">
                    <span className="font-medium-title feature-title">
                      {tour.title}
                    </span>

                    <Row className="mb-5">
                      <div className="flex justify-between gap-3 mt-auto col-12 ">
                        <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4"></div>

                        <div
                          role="button"
                          className="float-end col-lg-4 col-4  location-text text-wrap"
                          onClick={() => handleShowModal(tour)}
                        >
                          <span
                            className="font-common fw-bold"
                            style={{ color: "#0a66c2" }}
                          >
                            Schedule{" "}
                          </span>
                          <i className="mdi mdi-calendar-check "></i>

                        </div>

                        <div>
                          <span
                            className="font-common fw-bold"
                            onClick={() => uploadImages(tour)}
                            style={{ color: "#0a66c2" }}
                          >
                            Upload image{" "}
                            <img
                              src={uploadImageIcon}
                              alt=""
                              style={{ width: "20px" }}
                            />
                          </span>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
      <div className="text-end mt-1">
        {allTours && allTours.length > visibleToursCount && (
          <Button onClick={handleShowMoreTours}>Show More</Button>
        )}

        {visibleToursCount > 8 && (
          <Button onClick={handleShowLessTours} className="ms-2">
            Show Less
          </Button>
        )}
      </div>
      <Modal show={uploadImage} onHide={handleUploadImageClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className="mb-5">
              <Col md={6} className="mt-2">
                <Form.Label className="form-label">Featured Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFeaturedImageChange}
                  ref={fileInputRefFeatured}
                  disabled={loadingFeatured}
                />
                {/* <PlusCircle
                  style={{ marginLeft: "280px", marginTop: "-64px" }}
                  onClick={handlePlusCircleClick}
                /> */}
              </Col>

              <div>
                {displayedFile?.length > 0 ? (
                  displayedFile?.map((file, index) => (
                    <div key={index} className="text-center">
                      {file.name}
                      <DashCircle
                        className="ms-3"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleRemoveFile(index)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted"></div>
                )}
              </div>

              <Button
                className={`w-25 h-25 mt-3 ms-3 ${
                  displayedFile.length === 0 ? "custom-disabled" : ""
                }`}
                onClick={handleFeatured}
                disabled={displayedFile.length === 0 || loadingFeatured}
              >
                {loadingFeatured ? "Uploading..." : "Submit"}
              </Button>
            </Row>

            <Row className="mb-5">
              <Col md={6} className="mt-1">
                <Form.Label className="form-label">Snapshot Image</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  ref={fileInputRefSnapshots}
                  onChange={handleSnapshotImagesChange}
                  id="SNAPSHOT"
                />
                {/* <PlusCircle
                  style={{ marginLeft: "280px", marginTop: "-64px" }}
                  onClick={handlePlusCircleClickSnapshot}
                /> */}
              </Col>

              <div>
                {displayedSnapshotFiles.length > 0 ? (
                  displayedSnapshotFiles.map((file, index) => (
                    <div key={index} className="text-center">
                      {file.name}
                      <DashCircle
                        className="ms-3"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleSnapshotRemoveFile(index)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted"></div>
                )}
              </div>

              <Button
                className={`w-25 h-25 align-center mt-3 ms-3 ${
                  displayedSnapshotFiles.length === 0 ? "custom-disabled" : ""
                }`}
                onClick={() => handleSnapShot()}
                disabled={
                  displayedSnapshotFiles.length === 0 || loadingSnapshot
                }
              >
                {loadingSnapshot ? "Uploading..." : "Submit"}
              </Button>
            </Row>
          </div>
          {/* <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={handleUploadImageClose}>
              Close
            </Button>
          </div> */}
        </Modal.Body>
      </Modal>

{/* Schedule Tour */}

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="font-common">Schedule Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="form-group font-common">
                  <Form.Label className="font-common">
                    Schedule Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="title"
                    name="scheduleTitle"
                    placeholder="Enter Scheduled title"
                    value={formData.scheduleTitle}
                    onChange={handleChange}
                    required
                  />

                  <Row className="mt-1">
                    <Col md={6}>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                      />
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="duration">
                        <Form.Label>Duration in days</Form.Label>
                        <Form.Control
                          type="number"
                          name="duration"
                          placeholder="Enter duration in days "
                          value={formData.duration}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col md={6}>
                      <Form.Group controlId="durationTime">
                        <Form.Label>Duration in time</Form.Label>
                        <Form.Control
                          type="text"
                          name="durationTime"
                          placeholder="Enter Duration Time"
                          value={formData.durationTime}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Label>Discount (%)</Form.Label>
                      <Form.Control
                        type="text"
                        id="discount"
                        name="discount"
                        placeholder="Enter discount"
                        value={formData.discount}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Row>

                  <div className="row mt-1">
                    <div className="col-md-6 mb-3">
                      <Form.Label>Price</Form.Label>
                      <InputGroup>
                        <div style={{ width: "35%" }}>
                          <Select
                            id="currency"
                            name="currency"
                            options={currencyOptions}
                            value={currencyOptions.find(
                              (option) => option.value === formData.currency
                            )}
                            onChange={handleSelectChange}
                            placeholder="currency"
                            required
                            styles={customStyles}
                            formatOptionLabel={formatOptionLabel}
                          />
                        </div>
                        <Form.Control
                          type="text"
                          id="price"
                          name="price"
                          placeholder="Enter Price"
                          value={formData.price}
                          onChange={handleChange}
                          required
                        />
                      </InputGroup>
                    </div>
                  </div>
                  
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  <Button type="submit" className="me-2 btn-primary">
                    Submit
                  </Button>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>


      {showEditModal && (
        <TourEdit
          tour={selectedTour}
          handleUpdateTour={(updatedTour) => {
            const updatedTours = displayedTours.map((tour) =>
              tour.tourId === updatedTour.tourId ? updatedTour : tour
            );
            setDisplayedTours(updatedTours);
          }}
          handleCancelEdit={() => setShowEditModal(false)}
          onCloseAndRefresh={handleCloseEditModalAndRefresh}
        />
      )}
      <Modal
        show={showDeleteConfirmModal}
        onHide={handleCloseDeleteConfirmModal}
      >
        <Modal.Header closeButton className="font-common">
          <Modal.Title> Delete Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this tour?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDeleteTour}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCloseDeleteConfirmModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default TourDetails;
