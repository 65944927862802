import React, { useEffect, useState } from "react";
import Navbar from "../common/navbar";
import Sidebar from "../sidebar/sidebar";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form } from "react-bootstrap";

const BookedTour = () => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const baseUrl = process.env.REACT_APP_EUREKA_SERVER_ENDPOINT;

  const [toursByCompany, settoursByCompany] = useState([]);
  const [allCompany, setAllCompany] = useState([]);

  const [selectedEnterpriseCompanyId, setSelectedEnterpriseCompanyId] =
    useState("");

  const { userData, token } = useSelector((state) => state);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  const handleInputChange = (e) => {
    setSelectedEnterpriseCompanyId(e.target.value);
  };

  useEffect(() => {
    if (selectedEnterpriseCompanyId) {
      fetchAllToursByCompanyId(selectedEnterpriseCompanyId);
    }
  }, [selectedEnterpriseCompanyId]);

  useEffect(() => {
    fetchAllToursByCompanyId();
  }, []);

  // const getAllCompany = async () => {
  //   try {
  //     const token = localStorage.getItem("token");

  //     const response = await fetch(`${baseUrl}/rest/auth/allVendors`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();
  //     setAllCompany(data.data);
  //   } catch (error) {
  //     console.error("Error fetching companies:", error);
  //     toast.error("Failed to fetch companies");
  //   }
  // };

  const fetchAllToursByCompanyId = async () => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/booking/enterprise/${userData.data.enterpriseCompanyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("tour data===>", data.data);

      settoursByCompany(data.data);
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  return (
    <>
      <div>
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="row d-flex align-items-center justify-content-between">
                <div className="col-8 mt-2 ms-3 me-4" style={{ width: "100%" }}>
                  <div className="mt-3 mb-3">
                    <h6>Booked Tour</h6>
                  </div>

                  {/* <div className="col-md-4">
                    <Form.Group
                      as={Col}
                      controlId="formCategory"
                      className="w-100"
                    >
                      <Form.Label className="mt-3 mb-3">
                        Company Name:
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedEnterpriseCompanyId}
                        onChange={handleInputChange}
                        name="companyName"
                      >
                        <option value="">Select company name</option>
                        {allCompany?.map((company) => (
                          <option
                            key={company.enterpriseCompanyId}
                            value={company.enterpriseCompanyId}
                          >
                            {company.companyName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div> */}

                  <div className="table-responsive shadow rounded me-4 mt-4">
                    <table className="table table-center bg-white mb-0">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3">Sr.no</th>
                          <th className="border-bottom p-3">
                            Number Of Participants
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Booking Date
                          </th>
                          {/* <th className="border-bottom p-3 text-center">
                            Date
                          </th> */}
                          <th className="border-bottom p-3 text-center">
                            Tour Name
                          </th>
                          <th className="border-bottom p-3 text-center">
                            User Name
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Mobile Number
                          </th>
                          <th className="border-bottom p-3 text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {toursByCompany?.map((tours, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{tours?.numberOfParticipants}</td>
                            <td>
                              {new Date(
                                tours?.bookingDate
                              ).toLocaleDateString()}
                            </td>
                            {/* <td>
                              {new Date(
                                tours.schedule.startDate
                              ).toLocaleDateString()}
                            </td> */}
                            <td>{tours.schedule.tour?.title}</td>
                            <td>{tours?.schedule.user?.firstName}</td>
                            <td>{tours?.schedule.user?.mobileNumber}</td>
                            <td>
                              <button
                                type="button"
                                className="tour-btn-green border-0 bg-success text-white"
                                style={{cursor:'none', fontSize:"12px"}}
                              >
                                {tours?.status}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookedTour;
