import React, { useEffect, useState } from "react";
import Navbar from "../common/navbar";
import Sidebar from "../sidebar/sidebar";
import { useSelector } from "react-redux";
import {
  Form,
  Col,
  Button,
  Table,
  Modal,
  Row,
  Card,
  Carousel,
  Dropdown,
} from "react-bootstrap";
import {
  ArrowLeft,
  ArrowRight,
  EyeFill,
  PencilFill,
  Plus,
  ThreeDotsVertical,
  Trash3,
  TrashFill,
} from "react-bootstrap-icons";
import { toast, ToastContainer } from "react-toastify";
import blankImg from "../../assets/images/download (2).png";
import CustomTimeInput from "./Itinarary/CustomTimeInput";
import ViewItinerary from "./Itinarary/ViewItinerary";
import EditItinerary from "./Itinarary/EditItinerary";
import axios from "axios";
import { formatDate } from "../../utils/dateUtils";

const ScheduledTour = () => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const [formData, setFormData] = useState({
    scheduleTitle: "",
    startDate: "",
    scheduleDate: "",
    duration: "",
    durationTime: "",
    price: "",
    discount: "",
  });
  const [allToursByVendorId, setAllToursByVendorId] = useState([]);
  const [selectedTourID, setSelectedTourID] = useState("");
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [showScheduleTable, setShowScheduleTable] = useState(false);
  const [selectedTourSchedules, setSelectedTourSchedules] = useState([]);
  const [selectedTourById, setSelectedTourById] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showEditItineraryPopup, setShowEditItineraryPopup] = useState(false);

  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const { userData, token } = useSelector((state) => state);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selecteSchedulesId, setSelecteSchedulesId] = useState();
  const [createdItineraries, setCreatedItineraries] = useState([]);
  const [allItineraries, setAllItineraries] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [valueFromNavbar, setValueFromNavbar] = useState(false);
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [visibleToursCount, setVisibleToursCount] = useState(6);
  const [displayedTours, setDisplayedTours] = useState([]);

  const [itineraries, setItineraries] = useState([
    { dayNumber: 1, description: "", startTime: "", endTime: "" },
  ]);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setItineraries([
      { dayNumber: 1, description: "", startTime: "", endTime: "" },
    ]);
  };
  useEffect(() => {
    fetchAllToursByVendorId();
  }, []);

  const handleShowMoreTours = () => {
    const nextToursCount = visibleToursCount + 6;
    setDisplayedTours(allToursByVendorId.slice(0, nextToursCount));
    setVisibleToursCount(nextToursCount);
  };

  const handleShowLessTours = () => {
    setDisplayedTours(allToursByVendorId.slice(0, 6));
    setVisibleToursCount(8);
  };

  const fetchAllToursByVendorId = async () => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/enterprise/${userData.data.enterpriseCompanyId}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("data", data);

      const toursWithStatusOne = data.data.filter((tour) => tour.status === 1);

      setAllToursByVendorId(toursWithStatusOne);
      setDisplayedTours(toursWithStatusOne.slice(0, visibleToursCount));
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const fetchScheduleDataByTourId = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/${selectedTourID}`);
      if (!response.ok) {
        throw new Error(
          `Error fetching tour schedules: ${response.statusText}`
        );
      }
      const data = await response.json();
      console.log("setSelectedTourSchedules====>", data.data.schedules);

      const schedulesWithStatusOne = data.data.schedules.filter(
        (schedule) => schedule.status === 1
      );

      setSelectedTourSchedules(schedulesWithStatusOne);
    } catch (error) {
      console.error("Error fetching tour schedules:", error);
    }
  };

  const handleCreate = async () => {
    if (!selectedTourSchedules.length) {
      console.error("Selected Schedule or Schedule ID is missing");
      toast.error("Please select a schedule before creating an itinerary.");
      return;
    }

    const selectedSchedule = selectedTourSchedules[0];
    if (!selectedSchedule.scheduleId) {
      console.error("Schedule ID is missing");
      toast.error("Selected schedule does not have a schedule ID.");
      return;
    }

    const payload = itineraries.map(
      ({ dayNumber, description, startTime, endTime }) => ({
        dayNumber,
        description,
        startTime,
        endTime,
      })
    );
    console.log("scheduleid", selectedSchedule.scheduleId);
    try {
      const response = await fetch(
        `${BaseAPI}/tour/create/itinerary/schedule/${selectedSchedule.scheduleId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error saving itinerary: ${errorText}`);
      }

      const data = await response.json();

      toast.success("Itinerary added successfully!");
      setCreatedItineraries([...createdItineraries, ...payload]);
      setShowModal(false);
      fetchAllItineraries();
      setItineraries([
        { dayNumber: 1, description: "", startTime: "", endTime: "" },
      ]);
    } catch (error) {
      console.error("Error creating itinerary:", error);
      toast.error("Failed to save itinerary.");
    }
  };

  useEffect(() => {
    if (selectedTourID) {
      fetchAllItineraries();
    }
  }, [selectedTourID]);

  const fetchAllItineraries = async (scheduleId) => {
    if (!scheduleId) {
      console.error("Schedule ID is missing");
      // toast.error("Please select a valid schedule.");
      return;
    }

    try {
      const response = await fetch(
        `${BaseAPI}/tour/itineraries/schedule/${scheduleId}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching itineraries: ${response.statusText}`);
      }
      const data = await response.json();
      setAllItineraries(data.data);
      console.log("Fetched Itineraries for schedule:", scheduleId, data.data);
    } catch (error) {
      console.error("Error fetching itineraries:", error);
      toast.error("Failed to fetch itineraries.");
    }
  };

  const handleAddRow = () => {
    setItineraries([
      ...itineraries,
      {
        dayNumber: itineraries.length + 1,
        description: "",
        startTime: "",
        endTime: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const newItineraries = itineraries.filter((_, i) => i !== index);
    setItineraries(newItineraries);
  };

  const handleItinerariesInputChange = (index, field, value) => {
    const newItineraries = itineraries.map((itinerary, i) =>
      i === index ? { ...itinerary, [field]: value } : itinerary
    );
    setItineraries(newItineraries);
  };

  const handleInputChange = (e) => {
    const tourId = e.target.value;
    setSelectedTourID(tourId);
    if (tourId) {
      setShowSubmitButton(true);
    }
  };

  const filteredTours = selectedTourID
    ? allToursByVendorId.filter((tour) => tour.tourId === selectedTourID)
    : allToursByVendorId;

  useEffect(() => {
    if (selectedTourID) {
      setShowScheduleForm(false);
      setShowScheduleTable(true);
      const selectedTour = allToursByVendorId.find(
        (tour) => tour.tourId === selectedTourID
      );

      console.log("allToursByVendorId===>", allToursByVendorId);
      if (selectedTour) {
        setSelectedTourById(selectedTour);
      }
    } else {
      setShowScheduleForm(false);
      setShowScheduleTable(false);
    }
    fetchScheduleDataByTourId();
  }, [selectedTourID, allToursByVendorId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${BaseAPI}/tour/${selectedTourID}/schedule`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        toast.success("Schedule created successfully");
        fetchAllToursByVendorId();
        setShowScheduleTable(true);
        setShowScheduleForm(false);
        setShowSubmitButton(true);
        setFormData({
          startDate: "",
        });
        handlePopupClose();
      } else {
        console.error("Failed to create schedule");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePopupClose = () => {
    setShowEditPopup(false);
    setshowDeletePopup(false);
  };

  const handleEditSchedule = (schedule) => {
    console.log("Editing schedule:", schedule);

    setSelectedStartDate(schedule.scheduledDate);
    setSelecteSchedulesId(schedule.scheduleId);
    setShowEditPopup(true);
    setFormData({
      scheduleTitle: schedule.scheduleTitle,
      startDate: schedule.startDate,

      scheduleDate: schedule.scheduleDate,
      duration: schedule.duration,
      durationTime: schedule.durationTime,
      price: schedule.price,
      discount: schedule.discount,
    });
    console.log("formData:", formData);

    console.log("Selected Schedule ID:", schedule.scheduleId);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    console.log("Selected Schedule ID before update:", selecteSchedulesId);

    if (!selecteSchedulesId) {
      console.error("Schedule ID is undefined");
      return;
    }

    try {
      const response = await fetch(
        `${BaseAPI}/tour/update/schedule/${selecteSchedulesId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            scheduleTitle: formData.scheduleTitle,
            startDate: formData.startDate,
            scheduleDate: formData.scheduleDate,

            duration: formData.duration,
            durationTime: formData.durationTime,
            price: formData.price,
            discount: formData.discount,
          }),
        }
      );

      if (response.ok) {
        toast.success("Schedule updated successfully");
        fetchScheduleDataByTourId();
        setShowScheduleTable(true);
        setShowScheduleForm(false);
        setShowSubmitButton(true);

        setFormData({
          scheduleTitle: "",
          startDate: "",
          duration: "",
          durationTime: "",
          price: "",
          discount: "",
        });
        handlePopupClose();
      } else {
        console.error("Failed to update schedule");
        const errorData = await response.json();
        console.error("Error details:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleValueChange = (value) => {
    setValueFromNavbar(value);
  };

  const handleViewSchedule = (schedule) => {
    setSelectedSchedule(schedule);
  };

  const handleView = (schedule) => {
    console.log("setSelectedItinerary====>===>", schedule);
    // if(schedule.itinerary !== null){
    fetchAllItineraries(schedule.scheduleId);
    setSelectedItinerary(schedule);
    setShowViewPopup(true);
    // }
  };

  const handleEdit = (schedule) => {
    console.log("Editing Itinerary for Schedule: ", schedule);

    fetchAllItineraries(schedule.scheduleId)
      .then(() => {
        setSelectedItinerary(schedule);

        setShowEditItineraryPopup(true);
      })
      .catch((error) => {
        console.error("Error fetching itineraries: ", error);
        toast.error("Failed to fetch itineraries for editing.");
      });
  };

  const handleCardClick = (tourId) => {
    setSelectedTourID(tourId);
    fetchScheduleDataByTourId(tourId);
    fetchAllItineraries();
  };

  const [showDeleteScheduleConfirm, setShowDeleteScheduleConfirm] =
    useState(false);

  const confirmDeleteSchedule = (schedule) => {
    setScheduleToDelete(schedule);
    setShowDeleteScheduleConfirm(true);
  };

  const handleDeleteSchedule = async () => {
    if (!scheduleToDelete) return;

    try {
      const response = await axios.post(
        `${BaseAPI}/tour/delete/schedule/${scheduleToDelete.scheduleId}`
      );

      if (response.data.statusCode === 200) {
        toast.success("Schedule Deleted Successfully");
        fetchScheduleDataByTourId();
      } else {
        toast.error("Failed to delete schedule");
        console.error(
          "Failed to delete schedule:",
          response.data.statusMessage
        );
      }
    } catch (error) {
      console.error("An error occurred while deleting the schedule:", error);
      toast.error("An error occurred while deleting the schedule");
    } finally {
      setShowDeleteScheduleConfirm(false);
      setScheduleToDelete(null);
    }
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [scheduleToDelete, setScheduleToDelete] = useState(null);
  const [scheduleIdToDelete, setScheduleIdToDelete] = useState();

  const handleShowDeleteConfirm = () => {
    setShowDeleteConfirm(true);
    console.log(
      "Attempting to delete itinerary with schedule ID:",
      scheduleToDelete.scheduleId
    );
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
    setScheduleToDelete(null);
  };
  const handleConfirmDelete = () => {
    if (scheduleToDelete) {
      handleRemoveItinerary(scheduleToDelete);
      setShowDeleteConfirm(false);
      console.log(
        "Attempting to delete itinerary with schedule ID:",
        scheduleToDelete.scheduleId
      );
      alert("Deleting itinerary...");
    }
  };

  const handleDeleteItinarires = (schedule) => {
    console.log("scheduled=======================>", schedule);
    setScheduleIdToDelete(schedule.scheduleId);
    setShowDeleteConfirm(true);
  };

  const handleRemoveItinerary = async (schedule) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/delete/itineraries/${scheduleIdToDelete}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error deleting itinerary: ${errorText}`);
      }

      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success("Itinerary deleted successfully!");

        setAllItineraries((prevItineraries) =>
          prevItineraries.filter(
            (itinerary) => itinerary.scheduleId !== schedule.scheduleId
          )
        );
        setShowDeleteConfirm(false);

        setSelectedItinerary(null);
      } else {
        throw new Error(data.statusMessage || "Failed to delete itinerary");
      }
    } catch (error) {
      console.error("Error deleting itinerary:", error);
      toast.error("Failed to delete itinerary.");
    }
  };

  return (
    <>
      <div>
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onValueChange={handleValueChange} />
          <div
            className={`${
              !valueFromNavbar ? "layout-navbar-shifted" : "layout-navbar-full"
            }`}
          >
            <div className="layout-specing">
              <div className="d-flex align-items-center justify-content-between">
                <Form.Group
                  as={Col}
                  controlId="formCategory"
                  className="w-25 ms-4 mt-4"
                >
                  <Form.Label>Tour Name:</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedTourID}
                    onChange={handleInputChange}
                    name="category"
                    className="w-50 border-1 border-dark"
                  >
                    <option value="">Select a Tour</option>
                    {allToursByVendorId &&
                      Array.isArray(allToursByVendorId) &&
                      allToursByVendorId.map((tourName) => (
                        <option key={tourName.tourId} value={tourName.tourId}>
                          {tourName.title}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            {/* Tour Carousel Row */}
            <Row className="ms-3 me-3 mt-4">
              {Array.isArray(filteredTours) &&
                filteredTours.slice(0, visibleToursCount).map((tour, idx) => (
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={idx}
                    style={{ width: "33%" }}
                    onClick={() => handleCardClick(tour.tourId)}
                  >
                    <Card className="h-100" style={{ border: "none" }}>
                      <div className="carousel-hover">
                        <Carousel
                          className="carousel-overlay"
                          interval={null}
                          // nextIcon={<ArrowRight />}
                          // prevIcon={<ArrowLeft />}
                          nextIcon={
                            <span
                              style={{
                                backgroundColor: "black",
                                borderRadius: "50%",
                                padding: "5px",
                              }}
                            >
                              <ArrowRight style={{ color: "white" }} />
                            </span>
                          }
                          prevIcon={
                            <span
                              style={{
                                backgroundColor: "black",
                                borderRadius: "50%",
                                padding: "5px",
                              }}
                            >
                              <ArrowLeft style={{ color: "white" }} />
                            </span>
                          }
                        >
                          {tour.tourAssets && tour.tourAssets.length > 0 ? (
                            tour.tourAssets.map((asset, assetIdx) => (
                              <Carousel.Item
                                key={assetIdx}
                                className="card-img-container"
                              >
                                <img
                                  src={asset.assetUrl || blankImg}
                                  alt={`Slide ${assetIdx}`}
                                  onError={(e) => {
                                    console.log(
                                      "Image failed to load. Setting to default image."
                                    );
                                    e.target.src = blankImg;
                                  }}
                                  className="img-fluid"
                                  style={{ cursor: "pointer" }}
                                />
                              </Carousel.Item>
                            ))
                          ) : (
                            <Carousel.Item className="card-img-container">
                              <img
                                src={blankImg}
                                alt="Default image"
                                className="img-fluid"
                                style={{ cursor: "pointer" }}
                              />
                            </Carousel.Item>
                          )}
                        </Carousel>
                      </div>

                      <div className="d-flex flex-column mt-2">
                        <span
                          className="font-medium-title feature-title"
                          style={{ color: "#212529" }}
                        >
                          {tour.title}
                        </span>

                        <Row>
                          <div></div>
                        </Row>

                        <Row className="mb-2">
                          <div className="flex justify-between gap-3 mt-auto col-12 ">
                            <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4"></div>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>

            {!selectedTourID && (
              <div className="text-end mt-1">
                {allToursByVendorId.length > visibleToursCount && (
                  <Button onClick={handleShowMoreTours}>Show More</Button>
                )}
                {visibleToursCount > 8 && (
                  <Button onClick={handleShowLessTours} className="ms-2">
                    Show Less
                  </Button>
                )}
              </div>
            )}
            {/* Show Schedule Table */}
            {showScheduleTable &&
              selectedTourSchedules &&
              selectedTourSchedules.length > 0 && (
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-8 mt-1 ms-3" style={{ width: "100%" }}>
                    <div className="container-fluid">
                      <div className="row">
                        {selectedTourSchedules.map((schedule, index) => (
                          <div
                            key={index}
                            className="col-md-3 mb-4"
                            style={{ width: "25%" }}
                          >
                            <div className="card shadow-sm rounded">
                              <div className="card-body font-common">
                                <div className="font-medium-title">
                                  <span className="font-common fw-bold">
                                    Title:
                                  </span>
                                  <span className="ms-2">
                                    {schedule.scheduleTitle}
                                  </span>
                                </div>
                                <div className="font-medium-title">
                                  <span className="font-common fw-bold me-1">
                                    Start Date:
                                  </span>
                                  {formatDate(schedule.scheduleDate)}
                                </div>

                                <div className="font-medium-title">
                                  <span className="font-common fw-bold me-1">
                                    End Date:
                                  </span>
                                  {formatDate(schedule.endDate)}
                                </div>

                                <div className="font-medium-title">
                                  <span className="font-common fw-bold">
                                    Duration(Days):
                                  </span>
                                  <span className="ms-1"> {schedule.duration}</span>
                                </div>
                                {/* <div className="font-medium-title">
                                  <span>Duration Time:</span>{" "}
                                  {schedule.durationTime}
                                </div> */}
                                <div className="font-medium-title ">
                                  <span className="fw-bold font-common">
                                    Price:
                                  </span>
                                  <span className="ms-2">{schedule.price}</span>
                                </div>
                                <div className="font-medium-title ">
                                  <span className="fw-bold">Discount:</span>
                                  <span className="ms-2">
                                    {schedule.discount}
                                  </span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-2">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant=""
                                      bsPrefix="p-2"
                                      id="dropdown-basic"
                                      style={{
                                        cursor: "pointer",
                                        color: "#fff",
                                        backgroundColor: "#0a66c2",
                                      }}
                                    >
                                      Manage Itinerary
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => setShowModal(true)}
                                      >
                                        <Plus className="me-2" /> Add
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleView(schedule)}
                                      >
                                        <EyeFill className="me-2" /> View
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleEdit(schedule)}
                                      >
                                        <PencilFill className="me-2" /> Edit
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item
                                       
                                        onClick={() =>
                                          handleDeleteItinarires(schedule)
                                        }
                                      >
                                        <TrashFill className="me-2" /> Delete
                                      </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>

                                  <div style={{ position: "relative" }}>
                                    <Dropdown
                                      style={{
                                        position: "absolute",
                                        top: "-170px",
                                        right: "-10px",
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="link"
                                        bsPrefix="p-0"
                                        id="dropdown-basic"
                                        style={{
                                          cursor: "pointer",
                                          color: "black",
                                        }}
                                      >
                                        <ThreeDotsVertical />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu align="end">
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleViewSchedule(schedule)
                                          }
                                          className="d-flex align-items-center"
                                        >
                                          <EyeFill
                                            style={{ marginRight: "8px" }}
                                          />
                                          View
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleEditSchedule(schedule)
                                          }
                                          className="d-flex align-items-center"
                                        >
                                          <PencilFill
                                            style={{ marginRight: "8px" }}
                                          />
                                          Edit
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() =>
                                            confirmDeleteSchedule(schedule)
                                          }
                                        >
                                          <TrashFill className="me-2" /> Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {/* Edit Modals */}

            <Modal
              show={showEditPopup}
              onHide={handlePopupClose}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="font-common">Edit Schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Card style={{ border: "none" }}>
                  <Card.Body>
                    <Form>
                      <div className="form-group font-common">
                        <Form.Label className="font-common">
                          Schedule Title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="scheduleTitle"
                          name="scheduleTitle"
                          placeholder="Enter Scheduled title"
                          value={formData.scheduleTitle}
                          onChange={handleChange}
                        />

                        <Row className="mt-1">
                          <Col md={6}>
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              type="date"
                              id="startDate"
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleChange}
                            />
                          </Col>

                          <Col md={6}>
                            <Form.Group controlId="duration">
                              <Form.Label>Duration in days</Form.Label>
                              <Form.Control
                                type="number"
                                name="duration"
                                placeholder="Enter duration in days"
                                value={formData.duration}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mt-1">
                          <Col md={6}>
                            <Form.Group controlId="durationTime">
                              <Form.Label>Duration in time</Form.Label>
                              <Form.Control
                                type="text"
                                name="durationTime"
                                placeholder="Enter Duration Time"
                                value={formData.durationTime}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            <Form.Label>Discount (%)</Form.Label>
                            <Form.Control
                              type="text"
                              id="discount"
                              name="discount"
                              placeholder="Enter discount"
                              value={formData.discount}
                              onChange={handleChange}
                            />
                          </Col>

                          <Col md={6}>
                            <Form.Label>Price</Form.Label>
                            <Form.Control
                              type="number"
                              name="price"
                              placeholder="Enter Price"
                              value={formData.price}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3 d-flex justify-content-end">
                        <Button
                          variant="primary"
                          onClick={handleUpdate}
                          className="me-2"
                        >
                          Update
                        </Button>
                        <Button variant="secondary" onClick={handlePopupClose}>
                          Close
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Modal.Body>
            </Modal>

            {/* Add Itinerary Modals */}

            <Modal
              show={selectedSchedule !== null && !showEditItineraryPopup}
              onHide={() => setSelectedSchedule(null)}
            >
              <Modal.Header closeButton>
                <Modal.Title>View Schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={6}>
                    <strong>Schedule Title</strong>
                  </Col>
                  <Col md={6}>
                    <p>{selectedSchedule && selectedSchedule.scheduleTitle}</p>
                  </Col>
                  <Col md={6}>
                    <strong> Start Date</strong>
                  </Col>
                  <Col md={6}>
                    <p>{selectedSchedule && selectedSchedule.startDate}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Duration</strong>
                  </Col>
                  <Col md={6}>
                    <p>{selectedSchedule && selectedSchedule.duration}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Duration Time</strong>
                  </Col>
                  <Col md={6}>
                    <p>{selectedSchedule && selectedSchedule.durationTime}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Price</strong>
                  </Col>
                  <Col md={6}>
                    <p>{selectedSchedule && selectedSchedule.price}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Discount</strong>
                  </Col>
                  <Col md={6}>
                    <p>{selectedSchedule && selectedSchedule.discount}</p>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setSelectedSchedule(null)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal} size="lg" onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Itinerary</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table
                  className="table-no-vertical-lines"
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Day</th>
                      <th>Description</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itineraries &&
                      Array.isArray(itineraries) &&
                      itineraries.map((itinerary, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Form.Control
                              type="number"
                              value={itinerary.dayNumber}
                              onChange={(e) =>
                                handleItinerariesInputChange(
                                  index,
                                  "dayNumber",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={1}
                              value={itinerary.description}
                              onChange={(e) =>
                                handleItinerariesInputChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <CustomTimeInput
                              value={itinerary.startTime}
                              onChange={(value) =>
                                handleItinerariesInputChange(
                                  index,
                                  "startTime",
                                  value
                                )
                              }
                            />
                          </td>
                          <td>
                            <CustomTimeInput
                              value={itinerary.endTime}
                              onChange={(value) =>
                                handleItinerariesInputChange(
                                  index,
                                  "endTime",
                                  value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveRow(index)}
                            >
                              <Trash3 />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                <Button
                  variant="success"
                  className="mt-3"
                  onClick={handleAddRow}
                >
                  Add More
                </Button>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleCreate}>
                  Create
                </Button>
              </Modal.Footer>
            </Modal>

            {showViewPopup && (
              <ViewItinerary
                show={showViewPopup}
                handleClose={() => setShowViewPopup(false)}
                itineraries={allItineraries}
                scheduleId={selectedSchedule?.scheduleId}
                token={token}
              />
            )}

            {showEditItineraryPopup && selectedItinerary?.scheduleId && (
              <EditItinerary
                show={showEditItineraryPopup}
                handleClose={() => {
                  setShowEditItineraryPopup(false);
                  setSelectedItinerary(null);
                }}
                itineraries={allItineraries}
                scheduleId={selectedItinerary?.scheduleId}
                token={token}
              />
            )}

            <Modal show={showDeleteConfirm} onHide={handleCancelDelete}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this itinerary?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCancelDelete}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleRemoveItinerary}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showDeleteScheduleConfirm}
              onHide={() => setShowDeleteScheduleConfirm(false)}
              className="font-common"
            >
              <Modal.Header closeButton>
                <Modal.Title> Delete schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this schedule?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleDeleteSchedule}>
                  Yes
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeleteScheduleConfirm(false)}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ScheduledTour;
