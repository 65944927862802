import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/scss/style.scss';
import '@mdi/font/css/materialdesignicons.min.css';

const AdminSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
      <div className={`page-wrapper ${isSidebarOpen ? 'toggled' : ''}`}>
        <div id="sidebar" className="sidebar-wrapper sidebar-dark">
          <div
            className="sidebar-content"
            data-simplebar
            style={{ height: `calc(100% - 60px)` }}
          >
            <div className="sidebar-brand">
              <a href="/">
                <span className="sidebar-colored ms-2">Eureka-Trip</span>
              </a>
              <button onClick={handleToggleSidebar} className="btn btn-toggle-sidebar">
                {isSidebarOpen ? '<' : '>'}
              </button>
            </div>

            <ul className="sidebar-menu">
              <li className={location.pathname === '/admin-dashboard' ? 'active' : ''}>
                <a href="" onClick={(e) => handleNavigation(e, '/admin-dashboard')}>
                  <i className="mdi mdi-home me-3"></i>Dashboard
                </a>
              </li>

              <li className={location.pathname === '/all-vendors' ? 'active' : ''}>
                <a href="" onClick={(e) => handleNavigation(e, '/all-vendors')}>
                  <i className="mdi mdi-home me-3"></i>Enterprise Tour Company
                </a>
              </li>

              <li className={location.pathname === '/admin-tours' ? 'active' : ''}>
                <a href="" onClick={(e) => handleNavigation(e, '/admin-tours')}>
                  <i className="mdi mdi-map  me-3"></i>Tours
                </a>
              </li>

              <li className={location.pathname === '/theme-setting' ? 'active' : ''}>
                <a href="" onClick={(e) => handleNavigation(e, '/theme-setting')}>
                  <i className="mdi mdi-cog me-3"></i>Theme Settings
                </a>
              </li>

              <li className={location.pathname === '/booked-tours' ? 'active' : ''}>
                <a href="" onClick={(e) => handleNavigation(e, '/booked-tours')}>
                  <i className="mdi mdi-ticket me-3"></i>Booked Tours
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
